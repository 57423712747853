import "./subscribe.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Fragment, useState } from "react";
import useMobile from "../../hooks/useMobile";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const url =
  "https://amano-hilot.us1.list-manage.com/subscribe/post?u=0fefa2f358eded0c75fab6b26&amp;id=7f0b982664";

const CustomForm = ({ status, onValidated }) => {
  let [email, setEmail] = useState("");
  const message =
    status === "success"
      ? "Merci pour votre inscription à la newsletter."
      : "Une erreur s'est produite ou votre email est déja présente dans la liste.";
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
    setEmail("");
  };

  return (
    <div
      style={{
        // background: "#efefef",
        borderRadius: 2,
        padding: 10,
        display: "inline-block",
      }}
    >
      {status === "sending" && (
        <div style={{ color: "blue" }}>Envoi en cours...</div>
      )}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green", marginBottom: 15 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        style={{ fontSize: "1em", padding: 12 }}
        ref={(node) => (email = node)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Votre Email"
      />
      <br />
      <button
        style={{
          fontSize: "1em",
          padding: 15,
          backgroundColor: "#8537cc",
          marginTop: 20,
          borderRadius: 15,
          color: "white",
          width: "100%",
        }}
        onClick={submit}
      >
        Envoyer
      </button>
    </div>
  );
};

function Subscribe() {
  const { isMobile } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <Fragment>
      <div className={toggleActive ? "subscribe-dark" : "subscribe"}>
        <h1>inscription via email</h1>
        {!isMobile ? <hr size="1" className="subscribe-hr roundedHr" /> : null}
        <p>
          Saisissez votre adresse électronique pour vous abonner et recevoir la
          newsletter du site, <br /> elle comporte les news, la parution des
          nouveaux livres de la collection Amano'Hilot, ainsi que les offres
          ponctuelles.
        </p>
        <div className="subInput-container">
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Subscribe;
