import Tome1 from "../../../assets/tome1.png";
import Tome2 from "../../../assets/tome2.png";
import Tome3 from "../../../assets/tome3.png";
import Full from "../../../assets/full.png";
import Postures from "../../../assets/postures.jpg";
import Meridiens from "../../../assets/couverture-face-full-meridiens.png";
import Acupression from "../../../assets/couverture-face-full-acupression.png";

export const data = [
  {
    id: 1,
    href: "https://www.thebookedition.com/fr/le-livre-pratique-de-lacupression-p-383139.html",
    isNoDispo: false,
    isNew: true,
    isComingSoon: false,
    isExclu: false,
    price: "17.65 €",
    pages: "220 pages",
    img: Acupression,
    title: "Pratique d'acupression",
    subTitle: "Guide complet.",
  },
  {
    id: 2,
    href: "https://www.thebookedition.com/fr/meridiens-et-acupression-p-383141.html",
    isNoDispo: false,
    isNew: true,
    isComingSoon: false,
    isExclu: false,
    price: "16.95 €",
    pages: "192 pages",
    img: Meridiens,
    title: "Méridiens&Acupression",
    subTitle: "Petit manuel complet.",
  },
  {
    id: 3,
    href: "https://www.thebookedition.com/fr/yogalmanach-complet-tous-niveaux--p-382377.html",
    isNoDispo: false,
    isNew: true,
    isComingSoon: false,
    isExclu: false,
    price: "91.90 €",
    pages: "424 pages",
    img: Full,
    title: "YogAlmanach - complet",
    subTitle: "Tous niveaux",
  },
  {
    id: 4,
    href: "https://www.thebookedition.com/fr/yogalmanach-tome-3-avances--p-381917.html",
    isNoDispo: false,
    isNew: false,
    price: "32.40 €",
    pages: "112 pages",
    img: Tome3,
    title: "YogAlmanach - Tome 3",
    subTitle: "Niveau avancé",
  },
  {
    id: 5,
    href: "https://www.thebookedition.com/fr/yogalmanach-tome-2-intermediaires--p-381879.html",
    isNoDispo: false,
    isNew: false,
    price: "49.70 €",
    pages: "206 pages",
    img: Tome2,
    title: "YogAlmanach - Tome 2",
    subTitle: "Niveau intermédiaire",
  },
  {
    id: 6,
    href: "https://www.thebookedition.com/fr/yogalmanach-tome-1-debutants--p-381878.html",
    isNoDispo: false,
    isNew: false,
    price: "40.50 €",
    pages: "161 pages",
    img: Tome1,
    title: "YogAlmanach - Tome 1",
    subTitle: "Niveau débutant",
  },
  {
    id: 7,
    href: "https://www.thebookedition.com/fr/42-postures-de-yoga-et-etirements-p-368479.html",
    isNoDispo: false,
    isNew: false,
    isBestSeller: true,
    price: "22 €",
    pages: "73 pages",
    img: Postures,
    title: "42 posture de Yoga et étirements...",
  },
];
