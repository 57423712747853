/* eslint-disable array-callback-return */
import React from "react";
import tabData from "./tabData";
import "./tabs.scss";

/*
 *
 * App wrapper
 *
 */
const TabPages = () => (
  <div className="tabPages">
    <TabContainer defaultTab={0} tabData={tabData[0]} />
  </div>
);

export default TabPages;

/*
 *
 * TabPanel component.
 * Displays content data passed down by TabContainer.
 *
 */
const TabPanel = ({ content, index }) => (
  <div key={index} className="TabPanel">
    <p>{content}</p>
  </div>
);

/*
 *
 * Tab component.
 *
 */
class Tab extends React.Component {
  _handleClick(e) {
    const { setCurrentTab, index } = this.props;
    setCurrentTab(index);
  }

  render() {
    const { text, isSelected } = this.props;
    return (
      <li
        className={`Tab${isSelected ? " is-selected" : ""}`}
        onClick={this._handleClick.bind(this)}
      >
        {text}
      </li>
    );
  }
}

/*
 *
 * TabContainer component.
 *
 */
class TabContainer extends React.Component {
  constructor(props) {
    super(props);

    // Set initial state
    this.state = {
      currentTab: this.props.defaultTab,
    };

    // Dummy data
    this._tabData = this.props.tabData;
  }

  _setCurrentTab(currentTab) {
    // Don't re-render if current tab is clicked again
    if (this.state.currentTab === currentTab) {
      return;
    }
    this.setState({ currentTab });
  }

  _renderTabs(props) {
    const index = props;
    // Map data to Tab component.
    // Pass index down so we can send it up to this.state later.
    return (
      <ul key={index} className="Tabs">
        {this._tabData.map((tab, index) => {
          return (
            <Tab
              key={index}
              index={index}
              text={tab.name}
              isSelected={this.state.currentTab === index}
              setCurrentTab={this._setCurrentTab.bind(this)}
            />
          );
        })}
      </ul>
    );
  }

  _renderTabPanel() {
    // Map data to TabPanel component
    return this._tabData.map((tab, index) => {
      if (this.state.currentTab === index) {
        return <TabPanel key={index} content={tab.content} />;
      }
    });
  }

  render() {
    const Tabs = this._renderTabs();
    const TabPanel = this._renderTabPanel();
    return (
      <div className="TabContainer">
        {Tabs}
        {TabPanel}
      </div>
    );
  }
}
