import Plage from "../../../assets/products/plage.jpeg";
import Bain from "../../../assets/products/bain.jpeg";
import Debardeur from "../../../assets/products/debardeur.jpeg";
import Bagage from "../../../assets/products/bagage.jpeg";
import Legging from "../../../assets/products/legging.jpeg";
import Tapis from "../../../assets/products/tapis.jpeg";
import Cartes from "../../../assets/products/cartes.jpeg";
import Poster from "../../../assets/products/poster.jpg";
import Sticker from "../../../assets/products/stickers.jpg";
import Coussin from "../../../assets/products/coussin.jpg";
import IphoneAsanas from "../../../assets/products/iphone-asanas.jpg";
import CoussinSolAsanas from "../../../assets/products/coussin-sol-asanas.jpg";
import ToteAsanas from "../../../assets/products/tote-asanas.jpg";
import GaneshTenture from "../../../assets/products/tenture-ganesh.jpg";
import MandalaSamsung from "../../../assets/products/mandala-samsung.jpg";
import TapisHomme from "../../../assets/products/tapis-homme.jpeg";
import PlageHomme from "../../../assets/products/serviette-plage-homme.jpeg";
import TapisHommeFemme from "../../../assets/products/tapis-homme-femme.jpeg";
import BainHommeFemme from "../../../assets/products/serviette-bain-homme-femme.jpeg";
import BainHomme from "../../../assets/products/serviette-bain-homme.jpeg";
import Tote2 from "../../../assets/products/tote2.jpg";
import Coussin2 from "../../../assets/products/coussin2.jpg";
import Foulard from "../../../assets/products/foulard.jpg";
import TShirt from "../../../assets/products/t-shirt.jpg";
import CoussinH from "../../../assets/products/coussinH.jpg";

export const data = [
  {
    id: 1,
    title: "Serviette de plage",
    price: "60,57 €",
    href: "https://www.zazzle.fr/yoga_serviette_de_plage-256817481293004680",
    img: Plage,
    description:
      "Rendez-vous à la plage avec une serviette personnalisée à votre image qui sortira du lot. Dimensions : 178 cm x 90 cm. Le devant est en polyester et le dos 100 % coton. Impression par sublimation avec des designs éclatants qui durent longtemps. Lavable en machine, séchable en machine cycle doux. Pour la plage, la piscine, un pique-nique et tout autre activité extérieure",
  },
  {
    id: 2,
    title: "Serviette de bain",
    price: "40,36 €",
    href: "https://www.zazzle.fr/serviette_yoga-256279125507989065",
    img: Bain,
    description:
      "Personnalisez votre salle de bain avec des serviettes pour tous vos besoins. Dimensions : 76 cm x 152 cm. Le devant est en polyester et le dos 100 % coton. Impression par sublimation avec des designs éclatants qui durent longtemps. Lavable en machine, séchable en machine cycle doux",
  },
  {
    id: 3,
    title: "Débardeur",
    price: "49,25 €",
    href: "https://www.zazzle.fr/debardeur_yoga-256720808787602105",
    img: Debardeur,
    description:
      "Audacieux et démonstratif, ce débardeur rend tous les autres débardeurs obsolètes. Imprimé, coupé et cousu spécialement pour vous, vous recevrez un produit introuvable ailleurs. Dos nageur avec une coupe drapée et décontractée à la fois flatteuse et féminine ! Parfait pour toutes les occasions, votre nouveau débardeur favori vous attend. Taille et Forme. Le modèle mesure 1,72 m et porte une taille S.",
  },
  {
    id: 4,
    title: "Étiquette À Bagage",
    price: "17,00 €",
    href: "https://www.zazzle.fr/etiquette_a_bagage_yoga-256401318331230472",
    img: Bagage,
    description:
      "Démarquez-vous dans le carrousel à bagages avec une étiquette à bagage personnalisée de Zazzle ! Robuste et résistante aux intempéries, cette étiquette à bagage est prête pour tous les bagages. Imprimé grâce au procédé d'impression AcryliPrint ® HD, votre étiquette à bagage est parfaite pour afficher vos créations, textes et photos avec des couleurs éclatantes.",
  },
  {
    id: 5,
    title: "Leggings",
    price: "73,90 €",
    href: "https://www.zazzle.fr/legging_yoga-256998725428654219",
    img: Legging,
    description:
      "Une paire de leggings personnalisée pour être confortable tout en gardant votre style. Chaque paire est imprimée avant couture ce qui permet une impression sur toute la surface. Vous pouvez les porter encore et encore, ils ne perdront pas leur forme au fil des années. Commandez votre paire de leggings personnalisée dès aujourd'hui ! Car chaque paire est coupée et cousue, il est possible que les designs ne soient pas continus aux niveaux des coutures.",
  },
  {
    id: 6,
    title: "Tapis de Yoga",
    price: "65,60 €",
    href: "https://www.zazzle.fr/tapis_de_yoga_yoga_mat-256458437833551937",
    img: Tapis,
    description:
      "Ces tapis de yoga éco-responsables personnalisés antidérapants sont à la fois pratiques, esthétiques et vous permettent d'exprimer votre personnalité. Namaste. Dimensions : 183 cm x 61 cm ; 0,63 cm d'épaisseur. Léger, il pèse environ 1,4 kg. Tapis de qualité avec une sensation très douce et des membranes en mailles pour plus de stabilité. Éco-responsable, composé de vinyle cellulaire, sans BPA, phtalates et latex. Impression avec des couleurs vibrantes qui durent. Essuyer avec un chiffon humide après chaque utilisation",
  },
  {
    id: 7,
    title: "Jeu de cartes",
    price: "13,10 €",
    href: "https://www.zazzle.fr/jeu_de_cartes_yoga-256592514864117538",
    img: Cartes,
    description:
      "c'est une idée parfaite pour un cadeau d'anniversaire ou pour donner de la classe à un tournoi de poker. Cartes lisses et faciles à mélanger. Dimensions : 6,3 cm x 8,9 cm ; taille poker. 52 cartes à jouer et 2 jokers par paquet. Avec étui de rangement en carton, simple et élégant.",
  },
  {
    id: 8,
    title: "Poster",
    price: "11,87 €",
    href:
      "https://www.redbubble.com/fr/i/poster/Planche-Global-Asanas-42-postures-et-%C3%A9tirements-par-rodolphe-a/39899360.LVTDI",
    img: Poster,
    description:
      "Pour couvrir d'art les murs tout nus de votre appartement, votre bureau, votre chambre, votre studio, la niche de Médor... Imprimé sur du papier d'affiche semi-brillant 185 g/m². Dimension personnalisée : veuillez consulter notre guide pour les dimensions finales. Ces tailles comprennent une bordure blanche de 5 mm pour l'encadrement. Et sur 38 autres produits",
  },
  {
    id: 9,
    title: "Sticker",
    price: "2,90 €",
    href:
      "https://www.redbubble.com/fr/i/sticker/Sweet-Meditation-par-rodolphe-a/73999376.EJUG5",
    img: Sticker,
    description:
      "Parfait pour personnaliser votre ordinateur portable, vos cahiers, vos fenêtres, etc. Sticker en vinyle demi-découpé (kiss-cut), facile à décoller. Ultra résistant, y compris à l'eau. Une bordure blanche de 3,2 mm entoure chaque design. Fini mat. L'origine des stickers peut varier selon le type de sticker sélectionné. Et sur 22 autres produits",
  },
  {
    id: 10,
    title: "Coussin",
    price: "20,28 €",
    href:
      "https://www.redbubble.com/fr/i/coussin/Sweet-Meditation-par-rodolphe-a/73999376.5X2YF",
    img: Coussin,
    description:
      "Coussin décoratif imprimé. Il suffit parfois d'un rien pour donner du chien à son intérieur ! Housse 100 % polyester filé doux et résistant (peut être achetée avec le coussin de garnissage en polyester). Housse imprimée de chaque côté en couleurs vives, à la demande, rien que pour vous. Fermeture éclair dissimulée pour un aspect impeccable et un entretien facile. Pour un effet bien rebondi, préférez un coussin de garnissage légèrement plus grand que la housse. Et sur 22 autres produits",
  },
  {
    id: 11,
    title: "Coque iPhone",
    price: "16,66 €",
    href:
      "https://www.redbubble.com/fr/i/coque-iphone/Planche-Global-Asanas-42-postures-et-%C3%A9tirements-par-rodolphe-a/39899360.PGM2E",
    img: IphoneAsanas,

    description:
      "Coque souple et résistante, qui recouvre l'arrière et les bords du téléphone. La coque est traitée contre les traces de doigts et fabriquée en TPU, un matériau qui absorbe les chocs. Les couleurs sont imprimées sur la surface à aspect givré de la coque. Le design est visible à l'arrière et les côtés sont semi-transparents et donnent libre accès aux ports de l'appareil. Compatible avec le chargement sans fil Qi. Les coques d'iPhone 12, 12 Pro, 12 Pro Max, et 12 mini sont également compatibles avec le chargement MagSafe. Poids 26 g. Épaisseur 1,6 mm (1/16pouces)",
  },
  {
    id: 12,
    title: "Coussin de sol",
    price: "56,21 €",
    href:
      "https://www.redbubble.com/fr/i/coussin-de-sol/Planche-Global-Asanas-42-postures-et-%C3%A9tirements-par-rodolphe-a/39899360.TMTL5",
    img: CoussinSolAsanas,
    description:
      'Une housse de coussin de sol XXL. La paresse version 2.0. Housse 100 % polyester filé, imprimée à la demande de chaque côté. 90 x 90 cm (35" x 35"). Coussin de garnissage non fourni. La fermeture éclair est dissimulée pour un aspect impeccable et un entretien facile. Pour un effet bien rebondi, préférez un coussin de garnissage légèrement plus grand que la housse.',
  },
  {
    id: 13,
    title: "Tote bag - Asanas",
    price: "17,73 €",
    href:
      "https://www.redbubble.com/fr/i/tote-bag/Planche-Global-Asanas-42-postures-et-%C3%A9tirements-par-rodolphe-a/39899360.PJQVX",
    img: ToteAsanas,
    description:
      "La version luxe du tote bag. Résistant et stylé avec son tissu entièrement imprimé de votre œuvre préférée. Trois tailles au choix pour petit et grand bric-à-brac. Consultez le guide des tailles pour trouver celle qu'il vous faut. Toile robuste 100 % polyester. Bandoulières en coton super résistant de 2,5 cm (1 pouce) de large et 68 cm (21 pouces) de long pour les petits sacs, 71 cm (28 pouces) pour les sacs de taille moyenne et 74 cm (29 pouces) pour les grands sacs. Impression recto-verso par sublimation, aux couleurs durablement vives.",
  },
  {
    id: 14,
    title: "Tentures Ganesh",
    price: "37,46 €",
    href:
      "https://www.redbubble.com/fr/i/tenture/Ganesh-Home-Sweet-Home-par-rodolphe-a/40113917.PCFHB",
    img: GaneshTenture,
    description:
      "Tapisserie ou tenture murale... Qu'importe le nom, ce produit en jette. Impression à la demande en haute définition et couleurs vives. Tissu 100 % polyester léger, avec bords cousus pour une finition impeccable. Lavage doux à froid en machine et séchage à l'air libre ou en machine à basse température. Ne pas utiliser d'agents blanchissants et ne pas repasser. Remarque : il existe une marge de tolérance de 2,5 à 5 cm (1 à 2 pouces) sur tous nos produits textiles imprimés.",
  },
  {
    id: 15,
    title: "Coque Samsung",
    price: "25,54 €",
    href:
      "https://www.redbubble.com/fr/i/coque-samsung/Mandala-N-2-par-rodolphe-a/40715731.FUPE7",
    img: MandalaSamsung,
    description:
      "Une coque de protection monobloc, fine et clipsable. Protégez votre appareil avec une coque antichoc en polycarbonate avec rebord. Des couleurs vives, injectées directement dans la coque. Une protection tout en finesse. Le design est visible sur l'intégralité de la coque, y compris les côtés, et vous avez libre accès à tous les ports de l'appareil. Compatible avec le chargement sans fil Qi et PowerShare. Épaisseur : 1 mm (3/64 pouce).",
  },
  {
    id: 16,
    title: "Tapis de Yoga",
    price: "65,25 €",
    href:
      "https://www.zazzle.fr/tapis_de_yoga_yoga_mat_illustration_homme-256382713335370498",
    img: TapisHomme,
    description:
      "Ces tapis de yoga éco-responsables personnalisés antidérapants sont à la fois pratiques, esthétiques et vous permettent d'exprimer votre personnalité. Namaste. Dimensions : 183 cm x 61 cm ; 0,63 cm d'épaisseur. Léger, il pèse environ 1,4 kg. Tapis de qualité avec une sensation très douce et des membranes en mailles pour plus de stabilité. Éco-responsable, composé de vinyle cellulaire, sans BPA, phtalates et latex. Impression avec des couleurs vibrantes qui durent. Essuyer avec un chiffon humide après chaque utilisation.",
  },
  {
    id: 17,
    title: "Serviette de plage",
    price: "60,26 €",
    href:
      "https://www.zazzle.fr/serviette_de_plage_yoga_illustration_homme-256814039111248114",
    img: PlageHomme,
    description:
      "Rendez-vous à la plage avec une serviette personnalisée à votre image qui sortira du lot. Dimensions : 178 cm x 90 cm. Le devant est en polyester et le dos 100 % coton. Impression par sublimation avec des designs éclatants qui durent longtemps. Lavable en machine, séchable en machine cycle doux. Pour la plage, la piscine, un pique-nique et tout autre activité extérieure",
  },
  {
    id: 18,
    title: "Tapis de Yoga",
    price: "65,25 €",
    href:
      "https://www.zazzle.fr/tapis_de_yoga_yoga_mat_homme_femme-256813352379932192",
    img: TapisHommeFemme,
    description:
      "Ces tapis de yoga éco-responsables personnalisés antidérapants sont à la fois pratiques, esthétiques et vous permettent d'exprimer votre personnalité. Namaste. Dimensions : 183 cm x 61 cm ; 0,63 cm d'épaisseur. Léger, il pèse environ 1,4 kg. Tapis de qualité avec une sensation très douce et des membranes en mailles pour plus de stabilité. Éco-responsable, composé de vinyle cellulaire, sans BPA, phtalates et latex. Impression avec des couleurs vibrantes qui durent. Essuyer avec un chiffon humide après chaque utilisation.",
  },
  {
    id: 19,
    title: "Serviette de bain",
    price: "40,15 €",
    href:
      "https://www.zazzle.fr/serviette_de_bain_yoga_homme_femme-256098026759090452",
    img: BainHommeFemme,
    description:
      "Personnalisez votre salle de bain avec des serviettes pour tous vos besoins. Dimensions : 76 cm x 152 cm. Le devant est en polyester et le dos 100 % coton. Impression par sublimation avec des designs éclatants qui durent longtemps. Lavable en machine, séchable en machine cycle doux",
  },
  {
    id: 20,
    title: "Serviette de bain",
    price: "40,15 €",
    href:
      "https://www.zazzle.fr/serviette_de_bain_yoga_illustration_homme-256865913338304298",
    img: BainHomme,
    description:
      "Personnalisez votre salle de bain avec des serviettes pour tous vos besoins. Dimensions : 76 cm x 152 cm. Le devant est en polyester et le dos 100 % coton. Impression par sublimation avec des designs éclatants qui durent longtemps. Lavable en machine, séchable en machine cycle doux",
  },
  {
    id: 21,
    title: "Tote bag",
    price: "17,73 €",
    href:
      "https://www.redbubble.com/fr/i/tote-bag/sweet-meditation-variant-YogA-par-rodolphe-a/75612316.PJQVX",
    img: Tote2,
    description:
      "La version luxe du tote bag. Résistant et stylé avec son tissu entièrement imprimé de votre œuvre préférée. Trois tailles au choix pour petit et grand bric-à-brac. Consultez le guide des tailles pour trouver celle qu'il vous faut. Toile robuste 100 % polyester. Bandoulières en coton super résistant de 2,5 cm (1 pouce) de large et 68 cm (21 pouces) de long pour les petits sacs, 71 cm (28 pouces) pour les sacs de taille moyenne et 74 cm (29 pouces) pour les grands sacs. Impression recto-verso par sublimation, aux couleurs durablement vives.",
  },
  {
    id: 22,
    title: "Coussin",
    price: "20,28 €",
    href:
      "https://www.redbubble.com/fr/i/coussin/sweet-meditation-variant-YogA-par-rodolphe-a/75612316.5X2YF",
    img: Coussin2,
    description:
      "Coussin décoratif imprimé. Il suffit parfois d'un rien pour donner du chien à son intérieur ! Housse 100 % polyester filé doux et résistant (peut être achetée avec le coussin de garnissage en polyester). Housse imprimée de chaque côté en couleurs vives, à la demande, rien que pour vous. Fermeture éclair dissimulée pour un aspect impeccable et un entretien facile. Pour un effet bien rebondi, préférez un coussin de garnissage légèrement plus grand que la housse. Et sur 22 autres produits",
  },
  {
    id: 23,
    title: "Foulard",
    price: "25,91 €",
    href:
      "https://www.redbubble.com/fr/i/foulard/Sweet-meditation-YogA-par-rodolphe-a/75610700.B15PI",
    img: Foulard,
    description:
      "Grand carré de 140 x 140 cm (55 pouces). L'œuvre est visible intégralement sur le devant et le dos. Tissu légèrement transparent en microfibre de polyester. Laver à la main seulement. Ne pas utiliser le nettoyage à sec ni le sèche linge.",
  },
  {
    id: 24,
    title: "T-shirt graphique",
    price: "28,70 €",
    href:
      "https://www.redbubble.com/fr/i/t-shirt/Sweet-meditation-man-YogA-par-rodolphe-a/75611835.1YYVU",
    img: TShirt,
    description:
      "Coupe droite classique et tissu doux. Le modèle mesure 1,86 m et porte une taille M. Le devant du t-shirt, entièrement imprimé, est composé de 96 % de polyester et de 4 % d'élasthanne. Le dos, les manches et le col sont de couleur unie et sont 100 % coton.",
  },
  {
    id: 25,
    title: "Coussin",
    price: "20,28 €",
    href:
      "https://www.redbubble.com/fr/i/coussin/Sweet-meditation-man-YogA-par-rodolphe-a/75611835.5X2YF",
    img: CoussinH,
    description:
      "Coussin décoratif imprimé. Il suffit parfois d'un rien pour donner du chien à son intérieur ! Housse 100 % polyester filé doux et résistant (peut être achetée avec le coussin de garnissage en polyester). Housse imprimée de chaque côté en couleurs vives, à la demande, rien que pour vous. Fermeture éclair dissimulée pour un aspect impeccable et un entretien facile. Pour un effet bien rebondi, préférez un coussin de garnissage légèrement plus grand que la housse. Et sur 22 autres produits",
  },
];
