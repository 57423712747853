import "./contactButton.scss";

const ContactButton = ({ setOpenForm }) => {
  return (
    <div className="contactButton-container">
      <div className="letter-image">
        <button
          className="contact-button-animate"
          id="contact"
          style={{ margingTop: "25px" }}
        >
          contactez moi
        </button>
        <div className="animated-mail">
          <div className="back-fold"></div>
          <div className="letter">
            <div className="letter-border"></div>
            <div className="letter-title"></div>
            <div className="letter-context"></div>
            <div className="letter-stamp">
              <div className="letter-stamp-inner"></div>
            </div>
          </div>
          <div className="top-fold"></div>
          <div className="body"></div>
          <div className="left-fold"></div>
        </div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};

export default ContactButton;
