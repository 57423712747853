/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRecoilState } from "recoil";
import shoppingCartContentAtom from "../../../../stateManager/shoppingCartContentAtom";
import uuid from "uuid-random";
import "./shoppingCart.css";
import sendOrderAtom from "../../../../stateManager/sendOrderAtom";
// import { useHistory } from "react-router-dom";
import ModalCheckout from "../checkoutSection/ModalCheckout";
import totalPriceAtom from "../../../../stateManager/totalPriceAtom";
// import axios from "axios";

const ShoppingCart = () => {
  // let history = useHistory();
  const [shoppingCartContent, setShoppingCartContent] = useRecoilState(
    shoppingCartContentAtom
  );
  const [sendOrder, setSendOrder] = useRecoilState(sendOrderAtom);
  const [totalPrice, setTotalPrice] = useRecoilState(totalPriceAtom);

  const totalSum = shoppingCartContent.reduce(
    (accumulator, current) => accumulator + current.price,
    0
  );
  let totalSumOrder = Math.round(totalSum * 100) / 100;
  setTotalPrice(totalSumOrder);

  const handleSendOrder = () => {
    setSendOrder([
      {
        orderId: uuid(),
        orderSum: totalSum,
        numberEbook: shoppingCartContent.length,
        ebookId: [shoppingCartContent.map((res) => res.id)],
      },
    ]);
  };
  return (
    <div className="container-shoppingCart">
      <div className="shopping-cart">
        <div className="shopping-cart-header">
          <i className="fa fa-shopping-cart cart-icon"></i>
          <span className="badge">{shoppingCartContent.length}</span>

          <div className="shopping-cart-total">
            <span className="lighter-text">Total:</span>
            <span className="main-color-text">
              {Math.round(totalSum * 100) / 100}€
            </span>
          </div>
        </div>

        <ul className="shopping-cart-items">
          {shoppingCartContent.map((res) => (
            <li key={res.id} className="clearfix">
              <img style={{ width: "22%" }} src={res.pict} alt="item1" />
              <span className="item-name">{res.name}</span>
              <span className="item-price">{res.price}€</span>
              <span className="item-quantity">Quantité: 1</span>
            </li>
          ))}
        </ul>
        <ModalCheckout handleSendOrder={handleSendOrder} />
        {shoppingCartContent.length && (
          <div
            className="clearBasket"
            onClick={() => setShoppingCartContent([])}
          >
            <p>Vider le panier</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCart;
