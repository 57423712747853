import "./pictureScene.css";
import Bg from "../../assets/bg-hero-2.jpg";
import BgDark from "../../assets/bg-hero-2-dark.png";
import LogoAnamo from "../../assets/logo-amano-hilot.png";
import LogoAnamoDark from "../../assets/logo-amano-hilot-dark.png";
import AnimateText from "../animateText/AnimateText";
import LetterAnimation from "../letterAnimation/LetterAnimation";
import { Helmet } from "react-helmet";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import useMobile from "../../hooks/useMobile";

const PictureScene = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  const { isMobile } = useMobile();
  return (
    <Fragment>
      <Helmet>
        <title>Amano'Hilot</title>
        <meta
          name="description"
          content="mano'Hilot, Consutant Auto-édition, je vous assiste dans toutes les étapes de publication de votre livre."
        />
      </Helmet>
      <div className="pictureScene" id="home">
        <img
          className={
            toggleActive
              ? "scale-in-hor-center darkMode"
              : "scale-in-hor-center "
          }
          src={toggleActive ? BgDark : Bg}
          alt=""
        />
        <div className="tracking-in-expand-fwd intro-textLogo">
          {toggleActive && !isMobile ? (
            <Fragment>
              {" "}
              <div className="backLogoDark"></div>
              <img
                className="PictureScene-logo PictureScene-logoDark"
                src={LogoAnamo}
                alt=""
              />
            </Fragment>
          ) : isMobile ? (
            <img className="PictureScene-logo" src={LogoAnamoDark} alt="" />
          ) : (
            <img className="PictureScene-logo" src={LogoAnamo} alt="" />
          )}
          <div className="text">
            <AnimateText />
            <h2 className="tracking-in-expand-fwd">
              <LetterAnimation />
            </h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PictureScene;
