import { useState, useEffect } from "react";

const useMobile = () => {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isLittleScreen = windowDimension <= 1599;
  const isMobile = windowDimension <= 500;
  const isTreizePouces = windowDimension === 1280;
  const isLittleMobile = windowDimension === 360;
  return {
    isMobile,
    isLittleScreen,
    isTreizePouces,
    isLittleMobile,
  };
};

export default useMobile;
