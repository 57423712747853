/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRecoilState } from "recoil";
import clickedDownloadEbookAtom from "../../../../stateManager/clickedDownloadEbook";
import paymentCompletedAtom from "../../../../stateManager/paymentCompletedAtom";
import shoppingCartContentAtom from "../../../../stateManager/shoppingCartContentAtom";
import PaymentEbooks from "../payment-ebooks/PaymentEbooks";
import "./checkoutSection.css";
import "./modalCheckout.css";
import sendOrderAtom from "../../../../stateManager/sendOrderAtom";

const ModalCheckout = ({ handleSendOrder, submitHandler }) => {
  const [shoppingCartContent, setShoppingCartContent] = useRecoilState(
    shoppingCartContentAtom
  );
  const [clickedDownloadEbook, setClickedDownloadEbook] = useRecoilState(
    clickedDownloadEbookAtom
  );
  const [paymentCompleted] = useRecoilState(paymentCompletedAtom);
  const [sendOrder] = useRecoilState(sendOrderAtom);

  const handleCloseModal = (e) => {
    if (paymentCompleted && clickedDownloadEbook < shoppingCartContent.length) {
      alert(
        "vous allez fermer la fenêtre sans avoir téléchargé tous vos achats !!!"
      );
      e.preventDefault();
    }
    if (clickedDownloadEbook === shoppingCartContent.length) {
      window.location.replace("/");
      setShoppingCartContent([]);
      setClickedDownloadEbook(0);
      localStorage.removeItem("basket");
    }
    if (!paymentCompleted && clickedDownloadEbook === 0) {
      setShoppingCartContent([]);
      setClickedDownloadEbook(0);
    }
  };
  return (
    <div className="ModalComponent">
      <a
        href="#openModal"
        onClick={handleSendOrder}
        className="button-shoppingCart"
      >
        Passer commande
      </a>

      <div id="openModal" className="modalbg">
        <div className="dialog">
          <a
            onClick={handleCloseModal}
            href="#close"
            title="Close"
            className="close"
          >
            X
          </a>
          <PaymentEbooks />
        </div>
      </div>
    </div>
  );
};

export default ModalCheckout;
