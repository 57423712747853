import Home from "./components/home/Home";
import { Switch, Route } from "react-router-dom";
import ContactForm from "./components/contact/contactForm/ContactForm";
import ReactGA from "react-ga";
import Lapidart from "./lapidart/Lapidart";
import Error404 from "./components/error404/Error404";
ReactGA.initialize("UA-15974768-3");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <ContactForm />
        </Route>
        <Route path="/lapidart">
          <Lapidart />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
