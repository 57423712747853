import Acupression from "../../../assets/couverture-face-full-acupression.png";
import Meridiens from "../../../assets/couverture-face-full-meridiens.png";
import Postures from "../../../assets/postures.jpg";
import AcupressEbook from "../../../assets/pdf/Guide-pratique-acupression.pdf";
import MeridiensEbook from "../../../assets/pdf/Meridiens-et-Acupression.pdf";
import PostureEbook from "../../../assets/pdf/livre asanas-final-ebook.pdf";
import uuid from "uuid-random";

export const dataEbooks = [
  {
    id: 0,
    img: Acupression,
    name: "Pratique d'acupression",
    resume:
      "Tout au long de cet ouvrage vous allez tout d'abord découvrir ce qu'est l'acupression, en théorie et en pratique, parcourir l'histoire de la médecine chinoise. Vous découvrirez des comparatifs entre l'acupression et les autres techniques de soins (acupuncture, EFT, massage, réflexologie, reiki, etc...) Vous apprendrez comment pratiquer l'acupression, vous verrez aussi quels sont les oportunités de formation, livres, vidéos, certification ou licence.",
    price: 9.9,
    promoPrice: 7.42,
    isPromo: true,
    auth: uuid(),
    link: AcupressEbook,
  },
  {
    id: 1,
    img: Meridiens,
    name: "Méridiens & Acupression",
    resume:
      "Tout au long de cet ouvrage vous allez tout d'abord découvrir ce qu'est l'acupression. Puis vous allez apprendre les points les plus courants, comment administrer l'acupression en générale et plus particulièrement, comment appliquer la bonne pression sur les points d'acupression, sur vous-même, sur quelqu'un d'autre ou bien sur votre bébé, et ce dans les meilleures conditions et en toute sécurité. Et enfin, vous apprendrez les 12 méridiens primaires, les 2 vaisseaux majeurs extraordinaires, ainsi que les 6 vaisseaux mineurs extraordinaires.",
    price: 9.9,
    promoPrice: 7.42,
    isPromo: true,
    auth: uuid(),
    link: MeridiensEbook,
  },
  {
    id: 2,
    img: Postures,
    name: "42 postures de Yoga",
    resume:
      "J'ai d'abord créé ces planches afin de les partager sur ma page Facebook. Suite aux demandes faites par les personnes qui me suivent sur cette  page, j'ai décidé de les faire en planches de différentes tailles sur différents supports. Le succès fût au rendez-vous, et énormément de commentaires positifs me sont revenus, ce concept de vulgarisation a plu au plus grand nombre.'. ",
    price: 7.9,
    promoPrice: 5.99,
    isPromo: true,
    auth: uuid(),
    link: PostureEbook,
  },
];
