import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import totalPriceAtom from "../../../../stateManager/totalPriceAtom";
import { useRecoilState } from "recoil";
import sendOrderAtom from "../../../../stateManager/sendOrderAtom";
import shoppingCartContentAtom from "../../../../stateManager/shoppingCartContentAtom";
import { dataEbooks } from "../dataEbooks";
import clickedDownloadEbookAtom from "../../../../stateManager/clickedDownloadEbook";
import paymentCompletedAtom from "../../../../stateManager/paymentCompletedAtom";
import useMobile from "../../../../hooks/useMobile";
import ChevronUp from "../../../../assets/6560620481543238868.svg";
import ChevronDown from "../../../../assets/3700266371543238866.svg";

// import uuid from "uuid-random";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51Isu1FFS9QbPT1XMz4kA8SwI1SHEkQOllLcVMokMfu9sEIi9wSlzT8CKBOEeZwGG0ZljRjD1Q5ADdlgfRm26IOGE002fLxRtcK"
);

const SuccessMessage = ({
  shoppingCartContent,
  handleClickDown,
  clickedDownloadEbook,
}) => {
  const OrderedEbooksList = () => {
    return (
      <span className="ebookLinkList">
        {shoppingCartContent.map((res) => (
          <span onClick={handleClickDown} className="book1Link" key={res.id}>
            <a
              className="book1Link"
              href={`
                ${
                  res.id === 0
                    ? dataEbooks[0].link
                    : res.id === 1
                    ? dataEbooks[1].link
                    : res.id === 2
                    ? dataEbooks[2].link
                    : null
                }
              `}
              target="new"
              download
            >
              <img src={res.pict} alt="cover" />
            </a>

            <h4>{res.name}</h4>
          </span>
        ))}
      </span>
    );
  };

  return (
    <div className="success-msg">
      <svg
        width="3em"
        height="3em"
        viewBox="0 0 16 16"
        className="bi bi-check2 flip-in-ver-right"
        fill="green"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </svg>
      <div className="title">Paiement effectué avec succès</div>
      <p style={{ marginTop: -2, marginBottom: -10 }}>
        Cliquez sur la pochette pour télécharger le e-book
      </p>
      <br />
      <sub style={{ color: "red", fontWeight: "bold", fontSize: 11 }}>
        Attention, le nombre de clique est limité à la quantité d'article,
        cliquez bien une fois sur chaque image, si vous cliquez plus d'une
        fois... vous ne pourrez plus télécharger le reste de votre achat. il
        faudra alors me contacter...
      </sub>
      <OrderedEbooksList />
      <br />
    </div>
  );
};

const Cart = ({
  sendOrder,
  shoppingCartContent,
  isMobile,
  handleClickChevron,
  clickedChevron,
}) => {
  function CopyingText() {
    /* Get the text field */
    var copyText = document.getElementById("copyText");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Texte copié : " + copyText.value);
  }
  return (
    <React.Fragment>
      <br />
      <h4 className=" headOrder d-flex justify-content-between align-items-center mb-3">
        <span className={isMobile ? "noVisible" : "text-muted"}>
          Votre Commande
        </span>
      </h4>
      <br />
      <ul
        className={
          clickedChevron
            ? "paiementOrderForm list-group mb-3  paiementOrderFormHidden"
            : "paiementOrderForm list-group mb-3 paiementOrderFormVisible"
        }
      >
        {sendOrder.map((res) => (
          <span key={res.orderId}>
            {!isMobile ? (
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                {shoppingCartContent.map((resShop) => (
                  <div
                    style={{
                      marginBottom: 22,
                      width: "80%",
                    }}
                    key={resShop.id}
                  >
                    <div>
                      <img
                        style={{ width: "35%" }}
                        src={resShop.pict}
                        alt="product"
                      />
                    </div>
                  </div>
                ))}
                <br />
              </li>
            ) : null}
            <li
              className={!isMobile ? "priceSectionNoMobile" : "price-section"}
            >
              <span className="badge bg-secondary badge-pill">
                {shoppingCartContent.length}
              </span>
              <span className="totalPrice">
                <span>Total (Euros)</span>
                <strong>{Math.round(res.orderSum * 100) / 100}€</strong>
              </span>
              {!isMobile ? null : (
                <span className="chevronPrice">
                  {!clickedChevron ? (
                    <img
                      style={{ width: "10%" }}
                      src={ChevronUp}
                      alt=""
                      onClick={handleClickChevron}
                    />
                  ) : (
                    <img
                      style={{ width: "10%" }}
                      src={ChevronDown}
                      alt=""
                      onClick={handleClickChevron}
                    />
                  )}
                </span>
              )}
            </li>
            <div className="preOrderNumber">
              <sub style={{ fontSize: 12, color: "darkred", margintop: -15 }}>
                <b> Numéro de pré-transaction :</b>{" "}
                <input
                  id="copyText"
                  type="text"
                  Value={res.orderId}
                  onClick={CopyingText}
                  className="uniqueIdNumber"
                  readOnly
                />
              </sub>
              <br />
              <sub style={{ fontSize: 10, color: "darkred", margintop: -15 }}>
                En cas de problème lors du paiement, vous devrez me fournir ce
                numéro impérativement, copiez le en cliquant dessus et collez le
                quelque part le temps de cette transaction.
              </sub>
            </div>
          </span>
        ))}
      </ul>
    </React.Fragment>
  );
};

function PaymentEbooks() {
  const [paymentCompleted, setPaymentCompleted] =
    useRecoilState(paymentCompletedAtom);
  const [totalPrice] = useRecoilState(totalPriceAtom);
  const [sendOrder] = useRecoilState(sendOrderAtom);
  const [shoppingCartContent] = useRecoilState(shoppingCartContentAtom);
  const [clickedDownloadEbook, setClickedDownloadEbook] = useRecoilState(
    clickedDownloadEbookAtom
  );
  const { isMobile } = useMobile();

  const handleClickDown = () => {
    setClickedDownloadEbook((prevState) => prevState + 1);
  };
  const [clickedChevron, setClickedChevron] = useState(false);

  const handleClickChevron = () => {
    if (clickedChevron) {
      setClickedChevron(false);
    }
    if (!clickedChevron) {
      setClickedChevron(true);
    }
  };

  // console.log("is little", isMobile);

  return (
    <div className="container-payment">
      <div className="py-5 text-center">
        <h4>Paiement de votre commande</h4>
      </div>

      <div className="row s-box">
        {paymentCompleted ? (
          <SuccessMessage
            handleClickDown={handleClickDown}
            shoppingCartContent={shoppingCartContent}
            clickedDownloadEbook={clickedDownloadEbook}
            setClickedDownloadEbook={setClickedDownloadEbook}
          />
        ) : (
          <React.Fragment>
            <div className="col-md-5 order-md-2 mb-4">
              <Cart
                isMobile={isMobile}
                sendOrder={sendOrder}
                shoppingCartContent={shoppingCartContent}
                handleClickChevron={handleClickChevron}
                clickedChevron={clickedChevron}
              />
            </div>
            <div className="cartSectionOrderForm col-md-7 order-md-1">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  amount={totalPrice}
                  setPaymentCompleted={setPaymentCompleted}
                  clickedChevron={clickedChevron}
                  setClickedChevron={setClickedChevron}
                />
              </Elements>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default PaymentEbooks;
