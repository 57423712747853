import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WriteMe from "../../../assets/images/write-me.jpg";

import "./contactForm.css";
import "../../../assets/styles/contactForm/main.css";
import "../../../assets/styles/contactForm/util.css";
import useMobile from "../../../hooks/useMobile";

const ContactForm = ({ toggleActive }) => {
  const { register, handleSubmit } = useForm();
  const { isMobile } = useMobile();

  const ReloadForm = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const ResetInput = () => {
    setTimeout(() => {
      document.getElementById("contact-form").reset();
    }, 2000);
  };

  const onSubmit = (data) => {
    emailjs
      .send("gmail", "template_wQIJppFE", data, "user_xKWRvlBTc0uT65Tf9D7wJ")
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          toast.success("Email envoyé avec succès", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          ResetInput();
          ReloadForm();
        },
        function (error) {
          console.log("FAILED...", error);
          toast.error("Erreur, Email non envoyé !!!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          ResetInput();
        }
      );
  };

  return (
    <React.Fragment>
      <div className="Form-layout row">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="container-contact2 mt-5">
          {!isMobile ? (
            <span className="contact2-form-title">
              <img
                src={WriteMe}
                className="img-responsive img-circle margin contact-img"
                alt="write me"
              />
              <br />
              Contactez moi !!
            </span>
          ) : null}
          <div className="row row-contct">
            {!isMobile ? (
              <div className="col-md-6 maps">
                <iframe
                  className="frame-Form"
                  width="440"
                  height="440"
                  src="https://maps.google.com/maps?width=440&amp;height=440&amp;hl=en&amp;q=Ambillou%2C%20France+(Amano-Hilot)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  title="ou-je suis!"
                ></iframe>
              </div>
            ) : null}
            <div className="col-md-6 right-col">
              <br />
              <h3 style={{ fontSize: 11 }} className="subtitle-Form ">
                (tous les champs du formulaire sont obligatoires)
              </h3>
              <br />
              <form id="Form-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row inner-Form">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="nom"
                        ref={register}
                        type="text"
                        className="form-control mt-2"
                        placeholder="Nom et Prénom"
                        id="nom"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="objet"
                        ref={register}
                        type="text"
                        className="form-control mt-2"
                        placeholder="Objet du message"
                        id="objet"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="email"
                        ref={register}
                        type="email"
                        className="form-control mt-2"
                        placeholder="Votre Email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="telephone"
                        id="telephone"
                        ref={register}
                        type="number"
                        className="form-control mt-2"
                        placeholder="Votre Téléphone"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="texte"
                        id="texte"
                        ref={register}
                        className="form-control"
                        placeholder="Ecrivez votre message ici"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <br />
                    <div className="form-group">
                      <label>Sujet Principal du message</label>
                      <br />
                      <select
                        className="form-control"
                        name="secteur"
                        id="secteur"
                        ref={register}
                        required
                      >
                        <option value="">Choisissez le sujet principal!</option>
                        <option id="Option simple" value="Option simple">
                          Édition - option simple
                        </option>
                        <option
                          id="Option avec couverture"
                          value="Option avec couverture"
                        >
                          Édition - option partielle
                        </option>
                        <option id="Option complète" value="Option complète">
                          Édition - Option complète
                        </option>
                        <option
                          id="Option complète + site"
                          value="Option complète + site"
                        >
                          Édition - Option complète + site
                        </option>
                        <option id="autre" value="autre">
                          Général - Autres sujets
                        </option>
                        <option id="commande groupée" value="commande groupée">
                          Acheter un livre - commande groupée
                        </option>
                        <option
                          id="dédicace spéciale"
                          value="dédicace spéciale"
                        >
                          Acheter un livre - Dédicace spéciale
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="col-12">
                    <button
                      className="btn btn-light btn draw-border"
                      type="submit"
                      style={{
                        padding: 12,
                        fontSize: 16,
                        width: "97%",
                      }}
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
                <br />
              </form>
              <div className="text-white bottom-col-right">
                <h2 className="text-uppercase mt-4 font-weight-bold">
                  Amano-Hilot <br /> Rodolphe AUGUSTO
                </h2>
                <br />
                {isMobile ? (
                  <Fragment>
                    <i className="fas fa-phone mt-3 title-Form"></i>{" "}
                    <a className="phoneNum" href="tel:+33659653495">
                      Vous souhaitez m'appeler ?
                    </a>
                    <br />
                    <br />
                  </Fragment>
                ) : null}
                <i className="fas fa-globe mt-3 title-Form"></i> 18 rue du
                Général De Gaulle <br /> 37340 Ambillou
                <br />
                <br />
                <div className="my-4">
                  <a
                    className="Form-link"
                    href="https://www.facebook.com/rodolphe.augusto.7"
                    target="new"
                  >
                    <i className="fab fa-facebook fa-3x pr-4"></i>
                  </a>
                  <a
                    className="Form-link"
                    href="https://www.instagram.com/rodolphelakotaspirit/"
                    target="new"
                  >
                    <i className="fab fa-instagram fa-3x pr-4"></i>
                  </a>
                  <a
                    className="Form-link"
                    href="https://www.linkedin.com/in/rodolphe-augusto-d%C3%A9veloppeur-full-stack-react-node-js-1a20b759/"
                    target="new"
                  >
                    <i className="fab fa-linkedin fa-3x"></i>
                  </a>
                  <a
                    className="Form-link"
                    href="https://github.com/rodolphe37"
                    target="new"
                  >
                    <i className="fab fa-github-square fa-3x pl-4"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactForm;
