import MailchimpSubscribe from "react-mailchimp-subscribe";
// import AsanasBook from "../../../assets/pdf/couverture-face-final-bleue-with-new-logo.jpg";
import "./offertPdf.css";
// import myPDF from "../../../assets/pdf/livre asanas-final-ebook-free-version.pdf";
import coherenceCouv from "../../../assets/pdf/couverture-face.png";
import myPDF from "../../../assets/pdf/La cohérence du coeur.pdf";
import { Fragment, useState } from "react";

const url =
  "https://amano-hilot.us1.list-manage.com/subscribe/post?u=0fefa2f358eded0c75fab6b26&amp;id=7f0b982664";

const CustomForm = ({ status, onValidated }) => {
  let [email, setEmail] = useState("");
  const [clickedEmpty, setClickedEmpty] = useState(false);
  const message =
    status === "success"
      ? "Merci pour votre inscription à la newsletter."
      : "Une erreur s'est produite ou votre email est déja présente dans la liste.";
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
    setEmail("");
  };
  const handleClickedEmpty = () => {
    if (email.value !== "") {
      setClickedEmpty(false);
    } else {
      setClickedEmpty(true);
      setTimeout(() => {
        setClickedEmpty(false);
      }, 2200);
    }
  };

  return (
    <div className="content-freeOffert">
      {clickedEmpty && (
        <div style={{ color: "red", fontSize: 18 }}>
          Vous devez entrer votre email avant de cliquer.
        </div>
      )}
      {status === "sending" && (
        <div style={{ color: "blue", fontSize: 18 }}>Envoi en cours...</div>
      )}
      {status === "error" && (
        <div
          style={{ color: "red", fontSize: 18 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <Fragment>
          <div
            style={{ color: "green", marginBottom: 15 }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <a href={myPDF} download="La Cohérence Cardiaque.pdf">
            {" "}
            Cliquez pour télécharger le Ebook{" "}
          </a>
        </Fragment>
      )}
      <input
        style={{ fontSize: "1em", padding: 12 }}
        className="subscribe-input"
        ref={(node) => (email = node)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Votre Email"
      />
      <br />
      <button
        className="buttonFreeOffert"
        onClick={() => {
          handleClickedEmpty();
          submit();
        }}
      >
        Cliquez pour voir le lien de téléchargement
      </button>
    </div>
  );
};
const OffertPdf = () => {
  return (
    <div className="offertPdf-container">
      <div className="pictureBookOffert">
        <img src={coherenceCouv} alt="" />
      </div>
      <div className="offertPdf-rightContent">
        <div className="offertPdf-textArea">
          <p>
            Ce Ebook est en téléchargement gratuit pour une durée limitée.{" "}
            <br /> Entrez <b>votre email*</b> afin de télécharger le fichier
            joint.
          </p>
          <sub style={{ fontStyle: "italic" }}>
            *Vous recevrez la newsLetter mensuelle contenant les prochaines
            offres gratuites !
          </sub>
          <br />
          <br />
        </div>
        <span>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </span>
      </div>
    </div>
  );
};

export default OffertPdf;
