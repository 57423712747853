import "./presentation.css";
import Me from "../../assets/me.jpg";
import useMobile from "../../hooks/useMobile";
import { Slide } from "react-awesome-reveal";
import { Fragment, useState } from "react";
import SlideShowPictures from "../slideShowPictures/SlideShowPictures";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import { useRecoilState } from "recoil";
import AmanoGames from "../amanoGames/AmanoGames";
import Chevron from "../../assets/8347136151582545590-128.png";
import ChevronW from "../../assets/8347136151582545590-128-w.png";
import car from "../../assets/car.png";
const Presentation = () => {
  const { isMobile, isTreizePouces, isLittleScreen } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  const [clickedChevron, setClickedChevron] = useState(true);

  const handleClickChevron = () => {
    if (clickedChevron) {
      setClickedChevron(false);
    } else if (!clickedChevron) {
      setClickedChevron(true);
    }
  };

  return (
    <Fragment>
      <div
        className={toggleActive ? "presentation white" : "presentation"}
        id={!isTreizePouces ? "amano" : ""}
      >
        <div
          className={
            isTreizePouces
              ? "presentation-content presentation-content-13pouces"
              : "presentation-content"
          }
        >
          <span className="prez-fullContent">
            <div className={!isMobile ? "perspective-container" : ""}>
              <span className={isTreizePouces ? "photo-13pouces" : "photo"}>
                <img
                  className={
                    !isMobile
                      ? "card-prez slide-in-left"
                      : "prezPict none slide-in-left"
                  }
                  src={Me}
                  alt=""
                />
                {!isMobile && (
                  <div className="glow-wrap">
                    <i className="glow"></i>
                  </div>
                )}
              </span>
            </div>
            {isTreizePouces ? (
              <div className="pictContent">
                <SlideShowPictures />
                <img
                  className={
                    !isMobile
                      ? "card-prez slide-in-left"
                      : "prezPict none slide-in-left"
                  }
                  src={Me}
                  alt=""
                />
              </div>
            ) : (
              <SlideShowPictures />
            )}
          </span>
          {isTreizePouces ? (
            <div className="prez-13pouces" id="amano">
              <div className=" prez-text-13pouces">
                <span className="prez-title">
                  <h2>Qu'est-ce-que Amano'Hilot ?</h2>
                  {!isMobile ? (
                    <hr size="1" className="prez-hr roundedHr" />
                  ) : null}
                </span>

                <h2 style={{ fontSize: 18, fontWeight: 500 }}>
                  Je m'appelle Rodolphe Augusto, je suis thérapeute, auteur et
                  penseur.
                </h2>
                {!isMobile ? (
                  <Slide triggerOnce direction="right">
                    <br />
                    <span
                      className="history"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 59.99 59.99"
                        style={{ enableBackground: "new 0 0 59.99 59.99" }}
                        space="preserve"
                      >
                        <g id="XMLID_6_">
                          <path
                            id="XMLID_148_"
                            style={{ fill: "#556180" }}
                            d="M29.985,51c-11.579,0-21-9.421-21-21s9.421-21,21-21s21,9.421,21,21
		S41.565,51,29.985,51"
                          />
                          <path
                            id="XMLID_147_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,15c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,14.553,30.537,15,29.985,15"
                          />
                          <path
                            id="XMLID_146_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,49c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,48.553,30.537,49,29.985,49"
                          />
                          <path
                            id="XMLID_145_"
                            style={{ fill: "#FFFFFF" }}
                            d="M47.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C48.985,30.553,48.537,31,47.985,31"
                          />
                          <path
                            id="XMLID_144_"
                            style={{ fill: "#FFFFFF" }}
                            d="M13.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C14.985,30.553,14.537,31,13.985,31"
                          />
                          <path
                            id="XMLID_141_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,33c-2.206,0-4-1.794-4-4s1.794-4,4-4c2.206,0,4,1.794,4,4
		S32.192,33,29.985,33 M29.985,27c-1.103,0-2,0.897-2,2s0.897,2,2,2c1.103,0,2-0.897,2-2S31.088,27,29.985,27"
                          />
                          <path
                            id="XMLID_140_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,27c-0.552,0-1-0.447-1-1v-6c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v6
		C30.985,26.553,30.537,27,29.985,27"
                          />
                          <path
                            id="XMLID_139_"
                            style={{ fill: "#FFFFFF" }}
                            d="M18.985,41c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414
		l8.879-8.879c0.391-0.391,1.023-0.391,1.414,0c0.391,0.391,0.391,1.023,0,1.414l-8.879,8.879C19.498,40.902,19.241,41,18.985,41"
                          />
                          <path
                            id="XMLID_138_"
                            style={{ fill: "#74BE86" }}
                            d="M58.985,31c-0.552,0-1-0.447-1-1c0-15.439-12.561-28-28-28
		c-7.217,0-14.075,2.75-19.31,7.742c-0.399,0.381-1.033,0.366-1.414-0.033c-0.381-0.4-0.366-1.033,0.034-1.414
		C14.904,2.946,22.253,0,29.985,0c16.542,0,30,13.458,30,30C59.985,30.553,59.537,31,58.985,31"
                          />
                          <path
                            id="XMLID_137_"
                            style={{ fill: "#74BE86" }}
                            d="M17.985,10h-9V1c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v7h7c0.552,0,1,0.447,1,1
		C18.985,9.553,18.537,10,17.985,10"
                          />
                          <path
                            id="XMLID_105_"
                            style={{ fill: "#74BE86" }}
                            d="M29.546,59.01c-0.01-0.55,0.43-1.01,0.98-1.02c0.55-0.011,1.01,0.43,1.02,0.98
		c0.01,0.56-0.43,1.009-0.98,1.02h-0.02C30.005,59.99,29.555,59.56,29.546,59.01 M27.465,59.89v0.009
		c-0.55-0.049-0.96-0.529-0.91-1.089c0.04-0.55,0.53-0.96,1.08-0.911c0.55,0.051,0.95,0.531,0.91,1.08c-0.04,0.521-0.48,0.92-1,0.92
		C27.525,59.899,27.495,59.899,27.465,59.89 M32.546,58.899c-0.07-0.54,0.32-1.04,0.87-1.109c0.55-0.07,1.05,0.319,1.11,0.87
		c0.07,0.55-0.32,1.05-0.87,1.12v-0.01c-0.04,0.01-0.08,0.01-0.12,0.01C33.035,59.78,32.606,59.41,32.546,58.899 M24.396,59.479
		c-0.55-0.109-0.9-0.629-0.8-1.169c0.1-0.55,0.63-0.9,1.17-0.8c0.54,0.099,0.9,0.63,0.8,1.17c-0.1,0.48-0.52,0.81-0.99,0.81
		C24.515,59.49,24.456,59.49,24.396,59.479 M35.515,58.49c-0.13-0.54,0.21-1.08,0.75-1.2c0.54-0.12,1.07,0.21,1.2,0.75
		c0.12,0.54-0.22,1.08-0.75,1.2c-0.08,0.02-0.15,0.02-0.23,0.02C36.035,59.26,35.615,58.95,35.515,58.49 M21.376,58.74
		c-0.53-0.16-0.82-0.72-0.67-1.25c0.16-0.52,0.72-0.82,1.25-0.66c0.53,0.149,0.83,0.71,0.67,1.239
		c-0.13,0.431-0.53,0.711-0.96,0.711C21.575,58.78,21.476,58.77,21.376,58.74 M38.425,57.77c-0.18-0.53,0.1-1.1,0.62-1.27
		c0.52-0.181,1.09,0.09,1.27,0.62c0.18,0.52-0.1,1.09-0.62,1.27c-0.11,0.03-0.22,0.049-0.33,0.049
		C38.956,58.439,38.566,58.18,38.425,57.77 M18.456,57.7c-0.51-0.221-0.75-0.801-0.53-1.31c0.21-0.51,0.79-0.75,1.3-0.54
		c0.51,0.21,0.75,0.799,0.54,1.31c-0.16,0.38-0.53,0.61-0.92,0.61C18.715,57.77,18.586,57.75,18.456,57.7 M41.245,56.74
		c-0.23-0.5-0.02-1.091,0.49-1.32c0.5-0.24,1.09-0.021,1.32,0.479c0.24,0.5,0.02,1.101-0.48,1.33c-0.14,0.061-0.28,0.09-0.42,0.09
		C41.775,57.319,41.416,57.109,41.245,56.74 M15.666,56.35c-0.49-0.26-0.67-0.871-0.41-1.35c0.27-0.49,0.88-0.67,1.36-0.4
		c0.49,0.259,0.67,0.87,0.4,1.35c-0.18,0.34-0.52,0.529-0.88,0.529C15.976,56.479,15.816,56.439,15.666,56.35 M43.945,55.43
		c-0.29-0.47-0.13-1.08,0.34-1.37c0.48-0.28,1.09-0.121,1.37,0.35c0.29,0.47,0.13,1.09-0.34,1.37c-0.16,0.1-0.34,0.14-0.51,0.14
		C44.465,55.92,44.126,55.75,43.945,55.43 M13.015,54.729c-0.45-0.319-0.57-0.939-0.25-1.389c0.31-0.46,0.93-0.57,1.39-0.26
		c0.45,0.31,0.57,0.94,0.25,1.39c-0.19,0.28-0.5,0.429-0.82,0.429C13.385,54.899,13.195,54.85,13.015,54.729 M46.495,53.85
		c-0.33-0.44-0.24-1.07,0.2-1.4c0.44-0.33,1.07-0.24,1.4,0.21c0.33,0.44,0.24,1.069-0.21,1.4c-0.17,0.129-0.38,0.19-0.59,0.19
		C46.985,54.25,46.686,54.12,46.495,53.85 M10.555,52.84L10.555,52.84c-0.42-0.361-0.47-0.99-0.11-1.41
		c0.36-0.42,0.99-0.47,1.41-0.111c0.42,0.361,0.47,0.991,0.11,1.41c-0.2,0.231-0.48,0.351-0.76,0.351
		C10.976,53.08,10.745,53,10.555,52.84 M48.865,52.02c-0.38-0.411-0.35-1.041,0.06-1.42v0.009c0.4-0.38,1.04-0.349,1.41,0.061
		c0.37,0.399,0.35,1.03-0.06,1.41c-0.19,0.17-0.43,0.26-0.67,0.26C49.336,52.34,49.066,52.229,48.865,52.02 M8.305,50.71
		c-0.38-0.4-0.37-1.03,0.03-1.41c0.4-0.38,1.03-0.37,1.41,0.03c0.39,0.399,0.37,1.029-0.03,1.41c-0.19,0.19-0.44,0.28-0.69,0.28
		C8.765,51.02,8.495,50.92,8.305,50.71 M51.035,49.95c-0.41-0.37-0.45-1-0.08-1.41c0.36-0.42,0.99-0.45,1.41-0.09
		c0.41,0.369,0.45,1,0.08,1.409c-0.2,0.231-0.47,0.341-0.75,0.341C51.465,50.2,51.226,50.12,51.035,49.95 M6.275,48.359
		c-0.34-0.429-0.26-1.059,0.18-1.399c0.44-0.34,1.06-0.26,1.4,0.18c0.34,0.429,0.26,1.06-0.17,1.4c-0.19,0.14-0.4,0.21-0.62,0.21
		C6.775,48.75,6.476,48.62,6.275,48.359 M52.985,47.67c-0.45-0.32-0.55-0.95-0.23-1.4c0.32-0.44,0.95-0.55,1.4-0.23
		c0.45,0.33,0.55,0.95,0.23,1.399c-0.2,0.271-0.5,0.42-0.82,0.42C53.365,47.859,53.166,47.8,52.985,47.67 M4.505,45.819
		c-0.29-0.469-0.15-1.079,0.32-1.38c0.47-0.29,1.09-0.149,1.38,0.321c0.29,0.469,0.15,1.09-0.32,1.38c-0.17,0.1-0.35,0.15-0.53,0.15
		C5.025,46.29,4.695,46.12,4.505,45.819 M54.695,45.2c-0.48-0.27-0.65-0.881-0.37-1.36h-0.01c0.28-0.481,0.89-0.651,1.37-0.38
		c0.48,0.28,0.65,0.89,0.37,1.37c-0.18,0.319-0.52,0.5-0.87,0.5C55.015,45.33,54.845,45.29,54.695,45.2 M3.005,43.109L3.005,43.109
		c-0.24-0.5-0.04-1.099,0.46-1.339c0.5-0.24,1.09-0.041,1.34,0.459c0.24,0.5,0.03,1.101-0.47,1.34
		c-0.14,0.071-0.29,0.101-0.43,0.101C3.535,43.67,3.175,43.46,3.005,43.109 M56.135,42.569c-0.51-0.219-0.74-0.81-0.51-1.319
		c0.22-0.5,0.81-0.73,1.32-0.51c0.5,0.22,0.73,0.81,0.51,1.32c-0.17,0.37-0.53,0.6-0.92,0.6
		C56.405,42.66,56.265,42.63,56.135,42.569 M1.796,40.25c-0.19-0.521,0.07-1.09,0.59-1.28c0.52-0.19,1.1,0.08,1.28,0.599
		c0.19,0.521-0.07,1.091-0.59,1.281c-0.12,0.04-0.23,0.06-0.35,0.06C2.325,40.91,1.936,40.66,1.796,40.25 M57.296,39.81
		c-0.52-0.17-0.81-0.741-0.64-1.26c0.16-0.53,0.73-0.821,1.25-0.651c0.53,0.17,0.82,0.731,0.65,1.261
		c-0.14,0.42-0.53,0.69-0.95,0.69C57.505,39.85,57.396,39.84,57.296,39.81 M0.885,37.29c-0.14-0.54,0.19-1.08,0.72-1.21
		c0.54-0.141,1.08,0.19,1.22,0.72c0.13,0.54-0.2,1.08-0.73,1.22c-0.08,0.02-0.16,0.03-0.24,0.03
		C1.405,38.05,0.995,37.74,0.885,37.29 M58.166,36.93c-0.54-0.111-0.89-0.64-0.77-1.18c0.11-0.54,0.64-0.891,1.18-0.771
		c0.54,0.111,0.89,0.641,0.77,1.181c-0.09,0.47-0.51,0.79-0.97,0.79C58.305,36.95,58.235,36.95,58.166,36.93 M0.285,34.24
		c-0.08-0.54,0.3-1.051,0.85-1.131c0.54-0.079,1.05,0.301,1.13,0.851c0.08,0.55-0.3,1.05-0.85,1.13c-0.05,0.01-0.09,0.01-0.14,0.01
		C0.785,35.1,0.356,34.74,0.285,34.24 M58.726,33.99c-0.55-0.06-0.95-0.551-0.89-1.1c0.06-0.55,0.55-0.951,1.1-0.89
		c0.55,0.05,0.95,0.55,0.89,1.09c-0.05,0.519-0.49,0.9-0.99,0.9H58.726z M0.005,31.16c-0.02-0.56,0.41-1.02,0.96-1.04
		c0.55-0.02,1.02,0.41,1.04,0.96c0.02,0.55-0.41,1.02-0.96,1.04h-0.04C0.476,32.12,0.025,31.689,0.005,31.16 M57.985,30L57.985,30
		c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1s-0.45,1-1,1C58.436,31,57.985,30.55,57.985,30"
                          />
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      &nbsp;
                      <h2 style={{ fontSize: 20 }}>
                        La petite histoire du logo et du nom !
                      </h2>
                    </span>
                    <p>
                      Tout d'abord, pourquoi le logo de Amano'Hilot est une
                      salamandre?
                      <br />
                      <br />
                      C'est dû au premier contact que j'ai pû avoir avec cette
                      petite bête. <br />
                      Quand j'étais tout petit, je devait être en ce1, je
                      rentrais de l'école à pied jusqu'à chez moi, <br /> et
                      durant le trajet, j'ai vu une salamandre en train de
                      tenter de traverser la route,
                      <br />
                      du coup, plein de bienveillance, je l'ai prise pour
                      l'amener dans une petite forêt qu'il y avait de l'autre
                      côté de la route.
                      <br />
                      <br /> Je l'ai prise et posée dans le creux de ma main
                      délicatement le temps de traverser la route, puis je l'ai
                      déposée precautionneusement dans un petit fourré humide de
                      cette forêt.
                      <br />
                      De retour chez moi, je dit à ma mère ce que j'avais
                      vécu... elle me fie des grands yeux en me disant que
                      c'était un animal dangereux car il sécrété un poison
                      urticant en cas de danger.
                      <br />
                      <br />
                      C'est à ce moment là que j'ai compris que dans la nature
                      il n'y a rien de bon ni mauvais, la nature réagit juste en
                      cohérence avec le monde qui l'entoure, nous y compris.
                      <br />
                      <br /> Si j'avais était mal intentionné ou si j'avais
                      voulu la prendre pour l'emprisonnée chez moi elle n'aurait
                      pas eu la même réaction, là elle m'a laissé la prendre et
                      la sauver d'une mort quasi certaine sans rien me faire de
                      mal.
                      <br />
                      <br /> Le choix de ce logo est en symbole avec ce moment
                      magique dans ma vie. Voilà pour l'histoire de ce logo.
                      <br />
                      <br /> Pour ce qui est du nom "Amano'Hilot", amano est un
                      clin d'oeil à mes origines latine paternelle, "a mano"
                      signifie "la main" et Hilot est une technique de soin par
                      massage, originaire des Philippines, que je pratique.
                    </p>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="store"
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 58 58"
                        space="preserve"
                      >
                        <g>
                          <polygon
                            fill="#F0785A"
                            points="2.5,11 2.5,21.592 2.5,29.619 2.5,58 7.5,58 56.5,58 56.5,21.592 56.5,11 	"
                          />
                          <rect
                            x="4.5"
                            y="29"
                            fill="#B35A5B"
                            width="28"
                            height="23"
                          />
                          <rect
                            x="6.5"
                            y="31"
                            fill="#B0D3F0"
                            width="24"
                            height="19"
                          />
                          <path
                            fill="#ED7161"
                            d="M15.5,11H1.878c-0.189,0-0.326-0.181-0.274-0.363l4.062-8.824C6.174,0.708,7.279,0,8.496,0H17.5
		L15.5,11z"
                          />
                          <polygon
                            fill="#ED7161"
                            points="43.5,11 29.5,11 29.5,0 41.5,0 	"
                          />
                          <path
                            fill="#F0C419"
                            d="M22.5,25L22.5,25c-3.866,0-7-3.134-7-7v-7h14v7C29.5,21.866,26.366,25,22.5,25z"
                          />
                          <path
                            fill="#F0C419"
                            d="M50.5,25L50.5,25c-3.866,0-7-3.134-7-7v-7h13.893c0.059,0,0.107,0.048,0.107,0.107V18
		C57.5,21.866,54.366,25,50.5,25z"
                          />
                          <polygon
                            fill="#F3D55B"
                            points="29.5,11 15.5,11 17.5,0 29.5,0 	"
                          />
                          <path
                            fill="#F3D55B"
                            d="M57.145,11H43.5l-2-11h9.004c1.217,0,2.322,0.708,2.831,1.814l4.068,8.845
		C57.451,10.83,57.323,11,57.145,11z"
                          />
                          <path
                            fill="#D36259"
                            d="M8.5,25L8.5,25c-3.866,0-7-3.134-7-7v-6.718C1.5,11.126,1.626,11,1.782,11H15.5v7
		C15.5,21.866,12.366,25,8.5,25z"
                          />
                          <path
                            fill="#D36259"
                            d="M36.5,25L36.5,25c-3.866,0-7-3.134-7-7v-7h14v7C43.5,21.866,40.366,25,36.5,25z"
                          />
                          <rect
                            x="36.5"
                            y="29"
                            fill="#B35A5B"
                            width="16"
                            height="29"
                          />
                          <rect
                            x="38.5"
                            y="31"
                            fill="#B0D3F0"
                            width="12"
                            height="25"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M48.5,47c-0.553,0-1-0.447-1-1v-3c0-0.553,0.447-1,1-1s1,0.447,1,1v3C49.5,46.553,49.053,47,48.5,47
		z"
                          />
                          <g>
                            <path
                              fill="#FFFFFF"
                              d="M17.5,38c0.256,0,0.512-0.098,0.707-0.293l4-4c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-4,4c-0.391,0.391-0.391,1.023,0,1.414C16.988,37.902,17.244,38,17.5,38z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M17.5,43c0.256,0,0.512-0.098,0.707-0.293l2-2c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-2,2c-0.391,0.391-0.391,1.023,0,1.414C16.988,42.902,17.244,43,17.5,43z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M20.79,37.29c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.7
			c0.189,0.189,0.439,0.3,0.71,0.3c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71s-0.11-0.521-0.29-0.71
			C21.84,36.92,21.16,36.92,20.79,37.29z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M22.793,36.707C22.988,36.902,23.244,37,23.5,37s0.512-0.098,0.707-0.293l3-3
			c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3,3C22.402,35.684,22.402,36.316,22.793,36.707z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M25.793,37.293l-9,9c-0.391,0.391-0.391,1.023,0,1.414C16.988,47.902,17.244,48,17.5,48
			s0.512-0.098,0.707-0.293l9-9c0.391-0.391,0.391-1.023,0-1.414S26.184,36.902,25.793,37.293z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M29.21,35.29c-0.37-0.37-1.05-0.37-1.42,0c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.71
			C27.979,36.89,28.24,37,28.5,37s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71S29.39,35.479,29.21,35.29z"
                            />
                          </g>
                          <path
                            fill="#CB8252"
                            d="M7.5,58h-6l-0.916-5.495C0.54,52.241,0.744,52,1.012,52H8.5L7.5,58z"
                          />
                          <rect
                            x="3.5"
                            y="41"
                            fill="#7F6E5D"
                            width="2"
                            height="11"
                          />
                          <path
                            fill="#24AE5F"
                            d="M8.688,36.68c0.304-0.768,0.521-1.625,0.357-2.444c-0.071-0.355-0.233-0.696-0.456-0.965
		c-0.376-0.455-0.54-1.109-0.411-1.711c0.122-0.57,0.049-1.21-0.216-1.721c-0.483-0.931-1.444-1.452-2.399-1.588
		c-0.702-0.1-1.477,0.007-1.997,0.543l-0.618,0.534c-0.702,0.331-1.24,1.064-1.394,1.897c-0.083,0.452-0.053,0.926,0.077,1.364
		c0.194,0.653,0.033,1.359-0.338,1.908c-0.836,1.236-1.057,3.007-0.441,4.409c0.078,0.178,0.179,0.354,0.297,0.513
		c0.404,0.543,0.556,1.263,0.387,1.944c-0.039,0.156-0.065,0.316-0.08,0.475c-0.066,0.727,0.082,1.455,0.232,2.167
		c0.156,0.74,0.348,1.539,0.888,2.003c0.732,0.629,1.792,0.385,2.63-0.047c1.184-0.611,2.28-1.617,2.698-2.997
		c0.244-0.805,0.186-1.739-0.141-2.495c-0.33-0.764-0.33-1.671,0.148-2.33C8.23,37.7,8.485,37.194,8.688,36.68z"
                          />
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      <h2>L'entreprise Amano'Hilot !</h2>
                    </span>
                    <p className="subhead">
                      Amano'Hilot est une entreprise centrée sur le soin et le
                      bien-être depuis 2017 !
                      <br />
                      Au sein de cette établissement, je prodigue des soins
                      Hilot, mais pas seulement... <br />
                      <br />
                      Globalement, je concentre mon activité dans le soin à la
                      personne, mais aussi dans la promotion de la médecine
                      alternative et des activitées de bien-être.
                      <br />
                      <br /> J'anime une page facebook 'Amano-Hilot' ou je
                      partage des conseils pour être "un esprit sain dans un
                      corps sain", ceci par diverses types de publications,
                      cette page compte aujourd'hui plus de 100 000 mentions
                      J'aime & environ 104 000 followers, ainsi qu'un groupe,
                      'Médecines alternatives' qui lui compte environ 7000
                      membres sur le même réseau social. <br />
                      <br /> Étant auteur également, j'ai réellement conscience
                      de la difficulté à passer ce "Parcour du Combattant" quand
                      on es un auteur qui souhaite se voir édité...
                      <br /> C'est pour cette raison que j'ai décidé de créer
                      une section d'assistance à l'auto-édition, afin de vous
                      aider dans vos démarches, voir même les prendre en charge
                      à votre place.
                    </p>
                  </Slide>
                ) : (
                  <Fragment>
                    <br />
                    <span
                      className="history"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 59.99 59.99"
                        style={{ enableBackground: "new 0 0 59.99 59.99" }}
                        space="preserve"
                      >
                        <g id="XMLID_6_">
                          <path
                            id="XMLID_148_"
                            style={{ fill: "#556180" }}
                            d="M29.985,51c-11.579,0-21-9.421-21-21s9.421-21,21-21s21,9.421,21,21
		S41.565,51,29.985,51"
                          />
                          <path
                            id="XMLID_147_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,15c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,14.553,30.537,15,29.985,15"
                          />
                          <path
                            id="XMLID_146_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,49c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,48.553,30.537,49,29.985,49"
                          />
                          <path
                            id="XMLID_145_"
                            style={{ fill: "#FFFFFF" }}
                            d="M47.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C48.985,30.553,48.537,31,47.985,31"
                          />
                          <path
                            id="XMLID_144_"
                            style={{ fill: "#FFFFFF" }}
                            d="M13.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C14.985,30.553,14.537,31,13.985,31"
                          />
                          <path
                            id="XMLID_141_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,33c-2.206,0-4-1.794-4-4s1.794-4,4-4c2.206,0,4,1.794,4,4
		S32.192,33,29.985,33 M29.985,27c-1.103,0-2,0.897-2,2s0.897,2,2,2c1.103,0,2-0.897,2-2S31.088,27,29.985,27"
                          />
                          <path
                            id="XMLID_140_"
                            style={{ fill: "#FFFFFF" }}
                            d="M29.985,27c-0.552,0-1-0.447-1-1v-6c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v6
		C30.985,26.553,30.537,27,29.985,27"
                          />
                          <path
                            id="XMLID_139_"
                            style={{ fill: "#FFFFFF" }}
                            d="M18.985,41c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414
		l8.879-8.879c0.391-0.391,1.023-0.391,1.414,0c0.391,0.391,0.391,1.023,0,1.414l-8.879,8.879C19.498,40.902,19.241,41,18.985,41"
                          />
                          <path
                            id="XMLID_138_"
                            style={{ fill: "#74BE86" }}
                            d="M58.985,31c-0.552,0-1-0.447-1-1c0-15.439-12.561-28-28-28
		c-7.217,0-14.075,2.75-19.31,7.742c-0.399,0.381-1.033,0.366-1.414-0.033c-0.381-0.4-0.366-1.033,0.034-1.414
		C14.904,2.946,22.253,0,29.985,0c16.542,0,30,13.458,30,30C59.985,30.553,59.537,31,58.985,31"
                          />
                          <path
                            id="XMLID_137_"
                            style={{ fill: "#74BE86" }}
                            d="M17.985,10h-9V1c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v7h7c0.552,0,1,0.447,1,1
		C18.985,9.553,18.537,10,17.985,10"
                          />
                          <path
                            id="XMLID_105_"
                            style={{ fill: "#74BE86" }}
                            d="M29.546,59.01c-0.01-0.55,0.43-1.01,0.98-1.02c0.55-0.011,1.01,0.43,1.02,0.98
		c0.01,0.56-0.43,1.009-0.98,1.02h-0.02C30.005,59.99,29.555,59.56,29.546,59.01 M27.465,59.89v0.009
		c-0.55-0.049-0.96-0.529-0.91-1.089c0.04-0.55,0.53-0.96,1.08-0.911c0.55,0.051,0.95,0.531,0.91,1.08c-0.04,0.521-0.48,0.92-1,0.92
		C27.525,59.899,27.495,59.899,27.465,59.89 M32.546,58.899c-0.07-0.54,0.32-1.04,0.87-1.109c0.55-0.07,1.05,0.319,1.11,0.87
		c0.07,0.55-0.32,1.05-0.87,1.12v-0.01c-0.04,0.01-0.08,0.01-0.12,0.01C33.035,59.78,32.606,59.41,32.546,58.899 M24.396,59.479
		c-0.55-0.109-0.9-0.629-0.8-1.169c0.1-0.55,0.63-0.9,1.17-0.8c0.54,0.099,0.9,0.63,0.8,1.17c-0.1,0.48-0.52,0.81-0.99,0.81
		C24.515,59.49,24.456,59.49,24.396,59.479 M35.515,58.49c-0.13-0.54,0.21-1.08,0.75-1.2c0.54-0.12,1.07,0.21,1.2,0.75
		c0.12,0.54-0.22,1.08-0.75,1.2c-0.08,0.02-0.15,0.02-0.23,0.02C36.035,59.26,35.615,58.95,35.515,58.49 M21.376,58.74
		c-0.53-0.16-0.82-0.72-0.67-1.25c0.16-0.52,0.72-0.82,1.25-0.66c0.53,0.149,0.83,0.71,0.67,1.239
		c-0.13,0.431-0.53,0.711-0.96,0.711C21.575,58.78,21.476,58.77,21.376,58.74 M38.425,57.77c-0.18-0.53,0.1-1.1,0.62-1.27
		c0.52-0.181,1.09,0.09,1.27,0.62c0.18,0.52-0.1,1.09-0.62,1.27c-0.11,0.03-0.22,0.049-0.33,0.049
		C38.956,58.439,38.566,58.18,38.425,57.77 M18.456,57.7c-0.51-0.221-0.75-0.801-0.53-1.31c0.21-0.51,0.79-0.75,1.3-0.54
		c0.51,0.21,0.75,0.799,0.54,1.31c-0.16,0.38-0.53,0.61-0.92,0.61C18.715,57.77,18.586,57.75,18.456,57.7 M41.245,56.74
		c-0.23-0.5-0.02-1.091,0.49-1.32c0.5-0.24,1.09-0.021,1.32,0.479c0.24,0.5,0.02,1.101-0.48,1.33c-0.14,0.061-0.28,0.09-0.42,0.09
		C41.775,57.319,41.416,57.109,41.245,56.74 M15.666,56.35c-0.49-0.26-0.67-0.871-0.41-1.35c0.27-0.49,0.88-0.67,1.36-0.4
		c0.49,0.259,0.67,0.87,0.4,1.35c-0.18,0.34-0.52,0.529-0.88,0.529C15.976,56.479,15.816,56.439,15.666,56.35 M43.945,55.43
		c-0.29-0.47-0.13-1.08,0.34-1.37c0.48-0.28,1.09-0.121,1.37,0.35c0.29,0.47,0.13,1.09-0.34,1.37c-0.16,0.1-0.34,0.14-0.51,0.14
		C44.465,55.92,44.126,55.75,43.945,55.43 M13.015,54.729c-0.45-0.319-0.57-0.939-0.25-1.389c0.31-0.46,0.93-0.57,1.39-0.26
		c0.45,0.31,0.57,0.94,0.25,1.39c-0.19,0.28-0.5,0.429-0.82,0.429C13.385,54.899,13.195,54.85,13.015,54.729 M46.495,53.85
		c-0.33-0.44-0.24-1.07,0.2-1.4c0.44-0.33,1.07-0.24,1.4,0.21c0.33,0.44,0.24,1.069-0.21,1.4c-0.17,0.129-0.38,0.19-0.59,0.19
		C46.985,54.25,46.686,54.12,46.495,53.85 M10.555,52.84L10.555,52.84c-0.42-0.361-0.47-0.99-0.11-1.41
		c0.36-0.42,0.99-0.47,1.41-0.111c0.42,0.361,0.47,0.991,0.11,1.41c-0.2,0.231-0.48,0.351-0.76,0.351
		C10.976,53.08,10.745,53,10.555,52.84 M48.865,52.02c-0.38-0.411-0.35-1.041,0.06-1.42v0.009c0.4-0.38,1.04-0.349,1.41,0.061
		c0.37,0.399,0.35,1.03-0.06,1.41c-0.19,0.17-0.43,0.26-0.67,0.26C49.336,52.34,49.066,52.229,48.865,52.02 M8.305,50.71
		c-0.38-0.4-0.37-1.03,0.03-1.41c0.4-0.38,1.03-0.37,1.41,0.03c0.39,0.399,0.37,1.029-0.03,1.41c-0.19,0.19-0.44,0.28-0.69,0.28
		C8.765,51.02,8.495,50.92,8.305,50.71 M51.035,49.95c-0.41-0.37-0.45-1-0.08-1.41c0.36-0.42,0.99-0.45,1.41-0.09
		c0.41,0.369,0.45,1,0.08,1.409c-0.2,0.231-0.47,0.341-0.75,0.341C51.465,50.2,51.226,50.12,51.035,49.95 M6.275,48.359
		c-0.34-0.429-0.26-1.059,0.18-1.399c0.44-0.34,1.06-0.26,1.4,0.18c0.34,0.429,0.26,1.06-0.17,1.4c-0.19,0.14-0.4,0.21-0.62,0.21
		C6.775,48.75,6.476,48.62,6.275,48.359 M52.985,47.67c-0.45-0.32-0.55-0.95-0.23-1.4c0.32-0.44,0.95-0.55,1.4-0.23
		c0.45,0.33,0.55,0.95,0.23,1.399c-0.2,0.271-0.5,0.42-0.82,0.42C53.365,47.859,53.166,47.8,52.985,47.67 M4.505,45.819
		c-0.29-0.469-0.15-1.079,0.32-1.38c0.47-0.29,1.09-0.149,1.38,0.321c0.29,0.469,0.15,1.09-0.32,1.38c-0.17,0.1-0.35,0.15-0.53,0.15
		C5.025,46.29,4.695,46.12,4.505,45.819 M54.695,45.2c-0.48-0.27-0.65-0.881-0.37-1.36h-0.01c0.28-0.481,0.89-0.651,1.37-0.38
		c0.48,0.28,0.65,0.89,0.37,1.37c-0.18,0.319-0.52,0.5-0.87,0.5C55.015,45.33,54.845,45.29,54.695,45.2 M3.005,43.109L3.005,43.109
		c-0.24-0.5-0.04-1.099,0.46-1.339c0.5-0.24,1.09-0.041,1.34,0.459c0.24,0.5,0.03,1.101-0.47,1.34
		c-0.14,0.071-0.29,0.101-0.43,0.101C3.535,43.67,3.175,43.46,3.005,43.109 M56.135,42.569c-0.51-0.219-0.74-0.81-0.51-1.319
		c0.22-0.5,0.81-0.73,1.32-0.51c0.5,0.22,0.73,0.81,0.51,1.32c-0.17,0.37-0.53,0.6-0.92,0.6
		C56.405,42.66,56.265,42.63,56.135,42.569 M1.796,40.25c-0.19-0.521,0.07-1.09,0.59-1.28c0.52-0.19,1.1,0.08,1.28,0.599
		c0.19,0.521-0.07,1.091-0.59,1.281c-0.12,0.04-0.23,0.06-0.35,0.06C2.325,40.91,1.936,40.66,1.796,40.25 M57.296,39.81
		c-0.52-0.17-0.81-0.741-0.64-1.26c0.16-0.53,0.73-0.821,1.25-0.651c0.53,0.17,0.82,0.731,0.65,1.261
		c-0.14,0.42-0.53,0.69-0.95,0.69C57.505,39.85,57.396,39.84,57.296,39.81 M0.885,37.29c-0.14-0.54,0.19-1.08,0.72-1.21
		c0.54-0.141,1.08,0.19,1.22,0.72c0.13,0.54-0.2,1.08-0.73,1.22c-0.08,0.02-0.16,0.03-0.24,0.03
		C1.405,38.05,0.995,37.74,0.885,37.29 M58.166,36.93c-0.54-0.111-0.89-0.64-0.77-1.18c0.11-0.54,0.64-0.891,1.18-0.771
		c0.54,0.111,0.89,0.641,0.77,1.181c-0.09,0.47-0.51,0.79-0.97,0.79C58.305,36.95,58.235,36.95,58.166,36.93 M0.285,34.24
		c-0.08-0.54,0.3-1.051,0.85-1.131c0.54-0.079,1.05,0.301,1.13,0.851c0.08,0.55-0.3,1.05-0.85,1.13c-0.05,0.01-0.09,0.01-0.14,0.01
		C0.785,35.1,0.356,34.74,0.285,34.24 M58.726,33.99c-0.55-0.06-0.95-0.551-0.89-1.1c0.06-0.55,0.55-0.951,1.1-0.89
		c0.55,0.05,0.95,0.55,0.89,1.09c-0.05,0.519-0.49,0.9-0.99,0.9H58.726z M0.005,31.16c-0.02-0.56,0.41-1.02,0.96-1.04
		c0.55-0.02,1.02,0.41,1.04,0.96c0.02,0.55-0.41,1.02-0.96,1.04h-0.04C0.476,32.12,0.025,31.689,0.005,31.16 M57.985,30L57.985,30
		c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1s-0.45,1-1,1C58.436,31,57.985,30.55,57.985,30"
                          />
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      &nbsp;
                      <h2 style={{ fontSize: 20 }}>
                        La petite histoire du logo et du nom !
                      </h2>
                    </span>
                    <p>
                      Tout d'abord, pourquoi le logo de Amano'Hilot est une
                      salamandre?
                      <br />
                      <br />
                      C'est dû au premier contact que j'ai pû avoir avec cette
                      petite bête. <br />
                      Quand j'étais tout petit, je devais être en CE1, je
                      rentrais de l'école à pied jusqu'à chez moi, <br /> et
                      durant le trajet, j'ai vu une salamandre entrain de tenter
                      de traverser la route,
                      <br />
                      du coup, plein de bienveillance, je l'ai prise pour
                      l'amener dans une petite forêt qu'il y avait de l'autre
                      côté de la route.
                      <br />
                      <br /> Je l'ai prise et posée dans le creux de ma main
                      délicatement le temps de traverser la route, puis je l'ai
                      déposée precautionneusement dans un petit fourré humide de
                      cette forêt.
                      <br />
                      De retour chez moi, je dis à ma mère ce que j'avais
                      vécu... elle me fie des grands yeux en me disant que
                      c'était un animal dangereux car il sécrété un poison
                      urticant en cas de danger.
                      <br />
                      <br />
                      C'est à ce moment là que j'ai compris que dans la nature
                      il n'y a rien de bon ni mauvais, la nature réagit juste en
                      cohérence avec le monde qui l'entoure, nous y compris.
                      <br />
                      <br /> Si j'avais était mal intentionné ou si j'avais
                      voulu la prendre pour l'emprisonnée chez moi elle n'aurait
                      pas eu la même réaction, là elle m'a laissée la prendre et
                      la sauver d'une mort quasi certaine sans rien me faire de
                      mal.
                      <br />
                      <br /> Le choix de ce logo est en symbole avec ce moment
                      magique dans ma vie. Voilà pour l'histoire de ce logo.
                      <br />
                      <br /> Pour ce qui est du nom "Amano'Hilot", amano est un
                      clin d'oeil à mes origines latine paternelle, "a mano"
                      signifie "la main" et Hilot est une technique de soin par
                      massage, originaire des Philippines, que je pratique.
                    </p>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="store"
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 58 58"
                        space="preserve"
                      >
                        <g>
                          <polygon
                            fill="#F0785A"
                            points="2.5,11 2.5,21.592 2.5,29.619 2.5,58 7.5,58 56.5,58 56.5,21.592 56.5,11 	"
                          />
                          <rect
                            x="4.5"
                            y="29"
                            fill="#B35A5B"
                            width="28"
                            height="23"
                          />
                          <rect
                            x="6.5"
                            y="31"
                            fill="#B0D3F0"
                            width="24"
                            height="19"
                          />
                          <path
                            fill="#ED7161"
                            d="M15.5,11H1.878c-0.189,0-0.326-0.181-0.274-0.363l4.062-8.824C6.174,0.708,7.279,0,8.496,0H17.5
		L15.5,11z"
                          />
                          <polygon
                            fill="#ED7161"
                            points="43.5,11 29.5,11 29.5,0 41.5,0 	"
                          />
                          <path
                            fill="#F0C419"
                            d="M22.5,25L22.5,25c-3.866,0-7-3.134-7-7v-7h14v7C29.5,21.866,26.366,25,22.5,25z"
                          />
                          <path
                            fill="#F0C419"
                            d="M50.5,25L50.5,25c-3.866,0-7-3.134-7-7v-7h13.893c0.059,0,0.107,0.048,0.107,0.107V18
		C57.5,21.866,54.366,25,50.5,25z"
                          />
                          <polygon
                            fill="#F3D55B"
                            points="29.5,11 15.5,11 17.5,0 29.5,0 	"
                          />
                          <path
                            fill="#F3D55B"
                            d="M57.145,11H43.5l-2-11h9.004c1.217,0,2.322,0.708,2.831,1.814l4.068,8.845
		C57.451,10.83,57.323,11,57.145,11z"
                          />
                          <path
                            fill="#D36259"
                            d="M8.5,25L8.5,25c-3.866,0-7-3.134-7-7v-6.718C1.5,11.126,1.626,11,1.782,11H15.5v7
		C15.5,21.866,12.366,25,8.5,25z"
                          />
                          <path
                            fill="#D36259"
                            d="M36.5,25L36.5,25c-3.866,0-7-3.134-7-7v-7h14v7C43.5,21.866,40.366,25,36.5,25z"
                          />
                          <rect
                            x="36.5"
                            y="29"
                            fill="#B35A5B"
                            width="16"
                            height="29"
                          />
                          <rect
                            x="38.5"
                            y="31"
                            fill="#B0D3F0"
                            width="12"
                            height="25"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M48.5,47c-0.553,0-1-0.447-1-1v-3c0-0.553,0.447-1,1-1s1,0.447,1,1v3C49.5,46.553,49.053,47,48.5,47
		z"
                          />
                          <g>
                            <path
                              fill="#FFFFFF"
                              d="M17.5,38c0.256,0,0.512-0.098,0.707-0.293l4-4c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-4,4c-0.391,0.391-0.391,1.023,0,1.414C16.988,37.902,17.244,38,17.5,38z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M17.5,43c0.256,0,0.512-0.098,0.707-0.293l2-2c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-2,2c-0.391,0.391-0.391,1.023,0,1.414C16.988,42.902,17.244,43,17.5,43z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M20.79,37.29c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.7
			c0.189,0.189,0.439,0.3,0.71,0.3c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71s-0.11-0.521-0.29-0.71
			C21.84,36.92,21.16,36.92,20.79,37.29z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M22.793,36.707C22.988,36.902,23.244,37,23.5,37s0.512-0.098,0.707-0.293l3-3
			c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3,3C22.402,35.684,22.402,36.316,22.793,36.707z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M25.793,37.293l-9,9c-0.391,0.391-0.391,1.023,0,1.414C16.988,47.902,17.244,48,17.5,48
			s0.512-0.098,0.707-0.293l9-9c0.391-0.391,0.391-1.023,0-1.414S26.184,36.902,25.793,37.293z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M29.21,35.29c-0.37-0.37-1.05-0.37-1.42,0c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.71
			C27.979,36.89,28.24,37,28.5,37s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71S29.39,35.479,29.21,35.29z"
                            />
                          </g>
                          <path
                            fill="#CB8252"
                            d="M7.5,58h-6l-0.916-5.495C0.54,52.241,0.744,52,1.012,52H8.5L7.5,58z"
                          />
                          <rect
                            x="3.5"
                            y="41"
                            fill="#7F6E5D"
                            width="2"
                            height="11"
                          />
                          <path
                            fill="#24AE5F"
                            d="M8.688,36.68c0.304-0.768,0.521-1.625,0.357-2.444c-0.071-0.355-0.233-0.696-0.456-0.965
		c-0.376-0.455-0.54-1.109-0.411-1.711c0.122-0.57,0.049-1.21-0.216-1.721c-0.483-0.931-1.444-1.452-2.399-1.588
		c-0.702-0.1-1.477,0.007-1.997,0.543l-0.618,0.534c-0.702,0.331-1.24,1.064-1.394,1.897c-0.083,0.452-0.053,0.926,0.077,1.364
		c0.194,0.653,0.033,1.359-0.338,1.908c-0.836,1.236-1.057,3.007-0.441,4.409c0.078,0.178,0.179,0.354,0.297,0.513
		c0.404,0.543,0.556,1.263,0.387,1.944c-0.039,0.156-0.065,0.316-0.08,0.475c-0.066,0.727,0.082,1.455,0.232,2.167
		c0.156,0.74,0.348,1.539,0.888,2.003c0.732,0.629,1.792,0.385,2.63-0.047c1.184-0.611,2.28-1.617,2.698-2.997
		c0.244-0.805,0.186-1.739-0.141-2.495c-0.33-0.764-0.33-1.671,0.148-2.33C8.23,37.7,8.485,37.194,8.688,36.68z"
                          />
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      <h2>L'entreprise Amano'Hilot !</h2>
                    </span>
                    <p className="subhead">
                      Amano'Hilot est une entreprise centrée sur le soin et le
                      bien-être depuis 2017 !
                      <br />
                      Au sein de cette établissement, je prodigue des soins
                      Hilot, mais pas seulement... <br />
                      <br />
                      Globalement, je concentre mon activité dans le soin à la
                      personne, mais aussi dans la promotion de la médecine
                      alternative et des activitées de bien-être.
                      <br />
                      <br /> J'anime une page facebook 'Amano-Hilot' ou je
                      partage des conseils pour être "un esprit sain dans un
                      corps sain", ceci par diverses types de publications,
                      cette page compte aujourd'hui plus de 100 000 mentions
                      J'aime & environ 104 000 followers, ainsi qu'un groupe,
                      'Médecines alternatives' qui lui compte environ 7000
                      membres sur le même réseau social. <br />
                      <br /> Étant auteur également, j'ai réellement conscience
                      de la difficulté à passer ce "Parcour du Combattant" quand
                      on es un auteur qui souhaite se voir édité...
                      <br /> C'est pour cette raison que j'ai décidé de créer
                      une section d'assistance à l'auto-édition, afin de vous
                      aider dans vos démarches, voir même les prendre en charge
                      à votre place.
                    </p>
                  </Fragment>
                )}
              </div>
            </div>
          ) : (
            <div className="prez-text">
              <span className="prez-title">
                <h2>Qu'est-ce-que Amano'Hilot ?</h2>
                {!isMobile ? (
                  <hr size="1" className="prez-hr roundedHr" />
                ) : null}
              </span>

              <h2 style={{ fontSize: 18, fontWeight: 500 }}>
                Je m'appelle Rodolphe Augusto, je suis thérapeute, auteur et
                penseur.
              </h2>
              {!isMobile ? (
                <Slide triggerOnce direction="right">
                  <br />
                  <span
                    className="history"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 59.99 59.99"
                      style={{ enableBackground: "new 0 0 59.99 59.99" }}
                      space="preserve"
                    >
                      <g id="XMLID_6_">
                        <path
                          id="XMLID_148_"
                          style={{ fill: "#556180" }}
                          d="M29.985,51c-11.579,0-21-9.421-21-21s9.421-21,21-21s21,9.421,21,21
		S41.565,51,29.985,51"
                        />
                        <path
                          id="XMLID_147_"
                          style={{ fill: "#FFFFFF" }}
                          d="M29.985,15c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,14.553,30.537,15,29.985,15"
                        />
                        <path
                          id="XMLID_146_"
                          style={{ fill: "#FFFFFF" }}
                          d="M29.985,49c-0.552,0-1-0.447-1-1v-2c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v2
		C30.985,48.553,30.537,49,29.985,49"
                        />
                        <path
                          id="XMLID_145_"
                          style={{ fill: "#FFFFFF" }}
                          d="M47.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C48.985,30.553,48.537,31,47.985,31"
                        />
                        <path
                          id="XMLID_144_"
                          style={{ fill: "#FFFFFF" }}
                          d="M13.985,31h-2c-0.552,0-1-0.447-1-1c0-0.553,0.448-1,1-1h2c0.552,0,1,0.447,1,1
		C14.985,30.553,14.537,31,13.985,31"
                        />
                        <path
                          id="XMLID_141_"
                          style={{ fill: "#FFFFFF" }}
                          d="M29.985,33c-2.206,0-4-1.794-4-4s1.794-4,4-4c2.206,0,4,1.794,4,4
		S32.192,33,29.985,33 M29.985,27c-1.103,0-2,0.897-2,2s0.897,2,2,2c1.103,0,2-0.897,2-2S31.088,27,29.985,27"
                        />
                        <path
                          id="XMLID_140_"
                          style={{ fill: "#FFFFFF" }}
                          d="M29.985,27c-0.552,0-1-0.447-1-1v-6c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v6
		C30.985,26.553,30.537,27,29.985,27"
                        />
                        <path
                          id="XMLID_139_"
                          style={{ fill: "#FFFFFF" }}
                          d="M18.985,41c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414
		l8.879-8.879c0.391-0.391,1.023-0.391,1.414,0c0.391,0.391,0.391,1.023,0,1.414l-8.879,8.879C19.498,40.902,19.241,41,18.985,41"
                        />
                        <path
                          id="XMLID_138_"
                          style={{ fill: "#74BE86" }}
                          d="M58.985,31c-0.552,0-1-0.447-1-1c0-15.439-12.561-28-28-28
		c-7.217,0-14.075,2.75-19.31,7.742c-0.399,0.381-1.033,0.366-1.414-0.033c-0.381-0.4-0.366-1.033,0.034-1.414
		C14.904,2.946,22.253,0,29.985,0c16.542,0,30,13.458,30,30C59.985,30.553,59.537,31,58.985,31"
                        />
                        <path
                          id="XMLID_137_"
                          style={{ fill: "#74BE86" }}
                          d="M17.985,10h-9V1c0-0.553,0.448-1,1-1c0.552,0,1,0.447,1,1v7h7c0.552,0,1,0.447,1,1
		C18.985,9.553,18.537,10,17.985,10"
                        />
                        <path
                          id="XMLID_105_"
                          style={{ fill: "#74BE86" }}
                          d="M29.546,59.01c-0.01-0.55,0.43-1.01,0.98-1.02c0.55-0.011,1.01,0.43,1.02,0.98
		c0.01,0.56-0.43,1.009-0.98,1.02h-0.02C30.005,59.99,29.555,59.56,29.546,59.01 M27.465,59.89v0.009
		c-0.55-0.049-0.96-0.529-0.91-1.089c0.04-0.55,0.53-0.96,1.08-0.911c0.55,0.051,0.95,0.531,0.91,1.08c-0.04,0.521-0.48,0.92-1,0.92
		C27.525,59.899,27.495,59.899,27.465,59.89 M32.546,58.899c-0.07-0.54,0.32-1.04,0.87-1.109c0.55-0.07,1.05,0.319,1.11,0.87
		c0.07,0.55-0.32,1.05-0.87,1.12v-0.01c-0.04,0.01-0.08,0.01-0.12,0.01C33.035,59.78,32.606,59.41,32.546,58.899 M24.396,59.479
		c-0.55-0.109-0.9-0.629-0.8-1.169c0.1-0.55,0.63-0.9,1.17-0.8c0.54,0.099,0.9,0.63,0.8,1.17c-0.1,0.48-0.52,0.81-0.99,0.81
		C24.515,59.49,24.456,59.49,24.396,59.479 M35.515,58.49c-0.13-0.54,0.21-1.08,0.75-1.2c0.54-0.12,1.07,0.21,1.2,0.75
		c0.12,0.54-0.22,1.08-0.75,1.2c-0.08,0.02-0.15,0.02-0.23,0.02C36.035,59.26,35.615,58.95,35.515,58.49 M21.376,58.74
		c-0.53-0.16-0.82-0.72-0.67-1.25c0.16-0.52,0.72-0.82,1.25-0.66c0.53,0.149,0.83,0.71,0.67,1.239
		c-0.13,0.431-0.53,0.711-0.96,0.711C21.575,58.78,21.476,58.77,21.376,58.74 M38.425,57.77c-0.18-0.53,0.1-1.1,0.62-1.27
		c0.52-0.181,1.09,0.09,1.27,0.62c0.18,0.52-0.1,1.09-0.62,1.27c-0.11,0.03-0.22,0.049-0.33,0.049
		C38.956,58.439,38.566,58.18,38.425,57.77 M18.456,57.7c-0.51-0.221-0.75-0.801-0.53-1.31c0.21-0.51,0.79-0.75,1.3-0.54
		c0.51,0.21,0.75,0.799,0.54,1.31c-0.16,0.38-0.53,0.61-0.92,0.61C18.715,57.77,18.586,57.75,18.456,57.7 M41.245,56.74
		c-0.23-0.5-0.02-1.091,0.49-1.32c0.5-0.24,1.09-0.021,1.32,0.479c0.24,0.5,0.02,1.101-0.48,1.33c-0.14,0.061-0.28,0.09-0.42,0.09
		C41.775,57.319,41.416,57.109,41.245,56.74 M15.666,56.35c-0.49-0.26-0.67-0.871-0.41-1.35c0.27-0.49,0.88-0.67,1.36-0.4
		c0.49,0.259,0.67,0.87,0.4,1.35c-0.18,0.34-0.52,0.529-0.88,0.529C15.976,56.479,15.816,56.439,15.666,56.35 M43.945,55.43
		c-0.29-0.47-0.13-1.08,0.34-1.37c0.48-0.28,1.09-0.121,1.37,0.35c0.29,0.47,0.13,1.09-0.34,1.37c-0.16,0.1-0.34,0.14-0.51,0.14
		C44.465,55.92,44.126,55.75,43.945,55.43 M13.015,54.729c-0.45-0.319-0.57-0.939-0.25-1.389c0.31-0.46,0.93-0.57,1.39-0.26
		c0.45,0.31,0.57,0.94,0.25,1.39c-0.19,0.28-0.5,0.429-0.82,0.429C13.385,54.899,13.195,54.85,13.015,54.729 M46.495,53.85
		c-0.33-0.44-0.24-1.07,0.2-1.4c0.44-0.33,1.07-0.24,1.4,0.21c0.33,0.44,0.24,1.069-0.21,1.4c-0.17,0.129-0.38,0.19-0.59,0.19
		C46.985,54.25,46.686,54.12,46.495,53.85 M10.555,52.84L10.555,52.84c-0.42-0.361-0.47-0.99-0.11-1.41
		c0.36-0.42,0.99-0.47,1.41-0.111c0.42,0.361,0.47,0.991,0.11,1.41c-0.2,0.231-0.48,0.351-0.76,0.351
		C10.976,53.08,10.745,53,10.555,52.84 M48.865,52.02c-0.38-0.411-0.35-1.041,0.06-1.42v0.009c0.4-0.38,1.04-0.349,1.41,0.061
		c0.37,0.399,0.35,1.03-0.06,1.41c-0.19,0.17-0.43,0.26-0.67,0.26C49.336,52.34,49.066,52.229,48.865,52.02 M8.305,50.71
		c-0.38-0.4-0.37-1.03,0.03-1.41c0.4-0.38,1.03-0.37,1.41,0.03c0.39,0.399,0.37,1.029-0.03,1.41c-0.19,0.19-0.44,0.28-0.69,0.28
		C8.765,51.02,8.495,50.92,8.305,50.71 M51.035,49.95c-0.41-0.37-0.45-1-0.08-1.41c0.36-0.42,0.99-0.45,1.41-0.09
		c0.41,0.369,0.45,1,0.08,1.409c-0.2,0.231-0.47,0.341-0.75,0.341C51.465,50.2,51.226,50.12,51.035,49.95 M6.275,48.359
		c-0.34-0.429-0.26-1.059,0.18-1.399c0.44-0.34,1.06-0.26,1.4,0.18c0.34,0.429,0.26,1.06-0.17,1.4c-0.19,0.14-0.4,0.21-0.62,0.21
		C6.775,48.75,6.476,48.62,6.275,48.359 M52.985,47.67c-0.45-0.32-0.55-0.95-0.23-1.4c0.32-0.44,0.95-0.55,1.4-0.23
		c0.45,0.33,0.55,0.95,0.23,1.399c-0.2,0.271-0.5,0.42-0.82,0.42C53.365,47.859,53.166,47.8,52.985,47.67 M4.505,45.819
		c-0.29-0.469-0.15-1.079,0.32-1.38c0.47-0.29,1.09-0.149,1.38,0.321c0.29,0.469,0.15,1.09-0.32,1.38c-0.17,0.1-0.35,0.15-0.53,0.15
		C5.025,46.29,4.695,46.12,4.505,45.819 M54.695,45.2c-0.48-0.27-0.65-0.881-0.37-1.36h-0.01c0.28-0.481,0.89-0.651,1.37-0.38
		c0.48,0.28,0.65,0.89,0.37,1.37c-0.18,0.319-0.52,0.5-0.87,0.5C55.015,45.33,54.845,45.29,54.695,45.2 M3.005,43.109L3.005,43.109
		c-0.24-0.5-0.04-1.099,0.46-1.339c0.5-0.24,1.09-0.041,1.34,0.459c0.24,0.5,0.03,1.101-0.47,1.34
		c-0.14,0.071-0.29,0.101-0.43,0.101C3.535,43.67,3.175,43.46,3.005,43.109 M56.135,42.569c-0.51-0.219-0.74-0.81-0.51-1.319
		c0.22-0.5,0.81-0.73,1.32-0.51c0.5,0.22,0.73,0.81,0.51,1.32c-0.17,0.37-0.53,0.6-0.92,0.6
		C56.405,42.66,56.265,42.63,56.135,42.569 M1.796,40.25c-0.19-0.521,0.07-1.09,0.59-1.28c0.52-0.19,1.1,0.08,1.28,0.599
		c0.19,0.521-0.07,1.091-0.59,1.281c-0.12,0.04-0.23,0.06-0.35,0.06C2.325,40.91,1.936,40.66,1.796,40.25 M57.296,39.81
		c-0.52-0.17-0.81-0.741-0.64-1.26c0.16-0.53,0.73-0.821,1.25-0.651c0.53,0.17,0.82,0.731,0.65,1.261
		c-0.14,0.42-0.53,0.69-0.95,0.69C57.505,39.85,57.396,39.84,57.296,39.81 M0.885,37.29c-0.14-0.54,0.19-1.08,0.72-1.21
		c0.54-0.141,1.08,0.19,1.22,0.72c0.13,0.54-0.2,1.08-0.73,1.22c-0.08,0.02-0.16,0.03-0.24,0.03
		C1.405,38.05,0.995,37.74,0.885,37.29 M58.166,36.93c-0.54-0.111-0.89-0.64-0.77-1.18c0.11-0.54,0.64-0.891,1.18-0.771
		c0.54,0.111,0.89,0.641,0.77,1.181c-0.09,0.47-0.51,0.79-0.97,0.79C58.305,36.95,58.235,36.95,58.166,36.93 M0.285,34.24
		c-0.08-0.54,0.3-1.051,0.85-1.131c0.54-0.079,1.05,0.301,1.13,0.851c0.08,0.55-0.3,1.05-0.85,1.13c-0.05,0.01-0.09,0.01-0.14,0.01
		C0.785,35.1,0.356,34.74,0.285,34.24 M58.726,33.99c-0.55-0.06-0.95-0.551-0.89-1.1c0.06-0.55,0.55-0.951,1.1-0.89
		c0.55,0.05,0.95,0.55,0.89,1.09c-0.05,0.519-0.49,0.9-0.99,0.9H58.726z M0.005,31.16c-0.02-0.56,0.41-1.02,0.96-1.04
		c0.55-0.02,1.02,0.41,1.04,0.96c0.02,0.55-0.41,1.02-0.96,1.04h-0.04C0.476,32.12,0.025,31.689,0.005,31.16 M57.985,30L57.985,30
		c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1s-0.45,1-1,1C58.436,31,57.985,30.55,57.985,30"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    &nbsp;
                    <h2 style={{ fontSize: 18 }}>
                      La petite histoire du logo et du nom !
                    </h2>
                  </span>
                  <p>
                    Tout d'abord, pourquoi le logo de Amano'Hilot est une
                    salamandre?
                    <br />
                    <br />
                    C'est dû au premier contact que j'ai pû avoir avec cette
                    petite bête. <br />
                    Quand j'étais tout petit, je devais être en CE1, je rentrais
                    de l'école à pied jusqu'à chez moi, <br /> et durant le
                    trajet, j'ai vu une salamandre entrain de tenter de
                    traverser la route,
                    <br />
                    du coup, plein de bienveillance, je l'ai prise pour l'amener
                    dans une petite forêt qu'il y avait de l'autre côté de la
                    route.
                    <br />
                    <br /> Je l'ai prise et posée dans le creux de ma main
                    délicatement le temps de traverser la route, puis je l'ai
                    déposée precautionneusement dans un petit fourré humide de
                    cette forêt.
                    <br />
                    De retour chez moi, je dis à ma mère ce que j'avais vécu...
                    elle me fie des grands yeux en me disant que c'était un
                    animal dangereux car il sécrété un poison urticant en cas de
                    danger.
                    <br />
                    <br />
                    C'est à ce moment là que j'ai compris que dans la nature il
                    n'y a rien de bon ni mauvais, la nature réagit juste en
                    cohérence avec le monde qui l'entoure, nous y compris.
                    <br />
                    <br /> Si j'avais était mal intentionné ou si j'avais voulu
                    la prendre pour l'emprisonnée chez moi elle n'aurait pas eu
                    la même réaction, là elle m'a laissée la prendre et la
                    sauver d'une mort quasi certaine sans rien me faire de mal.
                    <br />
                    <br /> Le choix de ce logo est en symbole avec ce moment
                    magique dans ma vie. Voilà pour l'histoire de ce logo.
                    <br />
                    <br /> Pour ce qui est du nom "Amano'Hilot", amano est un
                    clin d'oeil à mes origines latine paternelle, "a mano"
                    signifie "la main" et Hilot est une technique de soin par
                    massage, originaire des Philippines, que je pratique.
                  </p>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="store"
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 58 58"
                      space="preserve"
                    >
                      <g>
                        <polygon
                          fill="#F0785A"
                          points="2.5,11 2.5,21.592 2.5,29.619 2.5,58 7.5,58 56.5,58 56.5,21.592 56.5,11 	"
                        />
                        <rect
                          x="4.5"
                          y="29"
                          fill="#B35A5B"
                          width="28"
                          height="23"
                        />
                        <rect
                          x="6.5"
                          y="31"
                          fill="#B0D3F0"
                          width="24"
                          height="19"
                        />
                        <path
                          fill="#ED7161"
                          d="M15.5,11H1.878c-0.189,0-0.326-0.181-0.274-0.363l4.062-8.824C6.174,0.708,7.279,0,8.496,0H17.5
		L15.5,11z"
                        />
                        <polygon
                          fill="#ED7161"
                          points="43.5,11 29.5,11 29.5,0 41.5,0 	"
                        />
                        <path
                          fill="#F0C419"
                          d="M22.5,25L22.5,25c-3.866,0-7-3.134-7-7v-7h14v7C29.5,21.866,26.366,25,22.5,25z"
                        />
                        <path
                          fill="#F0C419"
                          d="M50.5,25L50.5,25c-3.866,0-7-3.134-7-7v-7h13.893c0.059,0,0.107,0.048,0.107,0.107V18
		C57.5,21.866,54.366,25,50.5,25z"
                        />
                        <polygon
                          fill="#F3D55B"
                          points="29.5,11 15.5,11 17.5,0 29.5,0 	"
                        />
                        <path
                          fill="#F3D55B"
                          d="M57.145,11H43.5l-2-11h9.004c1.217,0,2.322,0.708,2.831,1.814l4.068,8.845
		C57.451,10.83,57.323,11,57.145,11z"
                        />
                        <path
                          fill="#D36259"
                          d="M8.5,25L8.5,25c-3.866,0-7-3.134-7-7v-6.718C1.5,11.126,1.626,11,1.782,11H15.5v7
		C15.5,21.866,12.366,25,8.5,25z"
                        />
                        <path
                          fill="#D36259"
                          d="M36.5,25L36.5,25c-3.866,0-7-3.134-7-7v-7h14v7C43.5,21.866,40.366,25,36.5,25z"
                        />
                        <rect
                          x="36.5"
                          y="29"
                          fill="#B35A5B"
                          width="16"
                          height="29"
                        />
                        <rect
                          x="38.5"
                          y="31"
                          fill="#B0D3F0"
                          width="12"
                          height="25"
                        />
                        <path
                          fill="#FFFFFF"
                          d="M48.5,47c-0.553,0-1-0.447-1-1v-3c0-0.553,0.447-1,1-1s1,0.447,1,1v3C49.5,46.553,49.053,47,48.5,47
		z"
                        />
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M17.5,38c0.256,0,0.512-0.098,0.707-0.293l4-4c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-4,4c-0.391,0.391-0.391,1.023,0,1.414C16.988,37.902,17.244,38,17.5,38z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M17.5,43c0.256,0,0.512-0.098,0.707-0.293l2-2c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-2,2c-0.391,0.391-0.391,1.023,0,1.414C16.988,42.902,17.244,43,17.5,43z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M20.79,37.29c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.7
			c0.189,0.189,0.439,0.3,0.71,0.3c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71s-0.11-0.521-0.29-0.71
			C21.84,36.92,21.16,36.92,20.79,37.29z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M22.793,36.707C22.988,36.902,23.244,37,23.5,37s0.512-0.098,0.707-0.293l3-3
			c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3,3C22.402,35.684,22.402,36.316,22.793,36.707z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M25.793,37.293l-9,9c-0.391,0.391-0.391,1.023,0,1.414C16.988,47.902,17.244,48,17.5,48
			s0.512-0.098,0.707-0.293l9-9c0.391-0.391,0.391-1.023,0-1.414S26.184,36.902,25.793,37.293z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M29.21,35.29c-0.37-0.37-1.05-0.37-1.42,0c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.71
			C27.979,36.89,28.24,37,28.5,37s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71S29.39,35.479,29.21,35.29z"
                          />
                        </g>
                        <path
                          fill="#CB8252"
                          d="M7.5,58h-6l-0.916-5.495C0.54,52.241,0.744,52,1.012,52H8.5L7.5,58z"
                        />
                        <rect
                          x="3.5"
                          y="41"
                          fill="#7F6E5D"
                          width="2"
                          height="11"
                        />
                        <path
                          fill="#24AE5F"
                          d="M8.688,36.68c0.304-0.768,0.521-1.625,0.357-2.444c-0.071-0.355-0.233-0.696-0.456-0.965
		c-0.376-0.455-0.54-1.109-0.411-1.711c0.122-0.57,0.049-1.21-0.216-1.721c-0.483-0.931-1.444-1.452-2.399-1.588
		c-0.702-0.1-1.477,0.007-1.997,0.543l-0.618,0.534c-0.702,0.331-1.24,1.064-1.394,1.897c-0.083,0.452-0.053,0.926,0.077,1.364
		c0.194,0.653,0.033,1.359-0.338,1.908c-0.836,1.236-1.057,3.007-0.441,4.409c0.078,0.178,0.179,0.354,0.297,0.513
		c0.404,0.543,0.556,1.263,0.387,1.944c-0.039,0.156-0.065,0.316-0.08,0.475c-0.066,0.727,0.082,1.455,0.232,2.167
		c0.156,0.74,0.348,1.539,0.888,2.003c0.732,0.629,1.792,0.385,2.63-0.047c1.184-0.611,2.28-1.617,2.698-2.997
		c0.244-0.805,0.186-1.739-0.141-2.495c-0.33-0.764-0.33-1.671,0.148-2.33C8.23,37.7,8.485,37.194,8.688,36.68z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <h2>L'entreprise Amano'Hilot !</h2>
                  </span>
                  <p className="subhead">
                    Amano'Hilot est une entreprise centrée sur le soin et le
                    bien-être depuis 2017 !
                    <br />
                    Au sein de cette établissement, je prodigue des soins Hilot,
                    mais pas seulement... <br />
                    <br />
                    Globalement, je concentre mon activité dans le soin à la
                    personne, mais aussi dans la promotion de la médecine
                    alternative et des activitées de bien-être.
                    <br />
                    <br /> J'anime une page facebook 'Amano-Hilot' ou je partage
                    des conseils pour être "un esprit sain dans un corps sain",
                    ceci par diverses types de publications, cette page compte
                    aujourd'hui plus de 100 000 mentions J'aime & environ 104
                    000 followers, ainsi qu'un groupe, 'Médecines alternatives'
                    qui lui compte environ 7000 membres sur le même réseau
                    social. <br />
                    <br /> Étant auteur également, j'ai réellement conscience de
                    la difficulté à passer ce "Parcour du Combattant" quand on
                    es un auteur qui souhaite se voir édité...
                    <br /> C'est pour cette raison que j'ai décidé de créer une
                    section d'assistance à l'auto-édition, afin de vous aider
                    dans vos démarches, voir même les prendre en charge à votre
                    place.
                  </p>
                </Slide>
              ) : (
                <Fragment>
                  <br />
                  <span
                    className="history"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.25 15.52L17.02 14.24L13.5 12.15V8H12Z"
                        fill="#F68217"
                      />
                    </svg>
                    &nbsp;
                    <h2 style={{ fontSize: 18 }}>
                      La petite histoire du logo et du nom !
                    </h2>
                  </span>
                  <p>
                    Tout d'abord, pourquoi le logo de Amano'Hilot est une
                    salamandre?
                    <br />
                    <br />
                    C'est dû au premier contact que j'ai pû avoir avec cette
                    petite bête. <br />
                    Quand j'étais tout petit, je devais être en CE1, je rentrais
                    de l'école à pied jusqu'à chez moi, <br /> et durant le
                    trajet, j'ai vu une salamandre entrain de tenter de
                    traverser la route,
                    <br />
                    du coup, plein de bienveillance, je l'ai prise pour l'amener
                    dans une petite forêt qu'il y avait de l'autre côté de la
                    route.
                    <br />
                    <br /> Je l'ai prise et posée dans le creux de ma main
                    délicatement le temps de traverser la route, puis je l'ai
                    déposée precautionneusement dans un petit fourré humide de
                    cette forêt.
                    <br />
                    De retour chez moi, je dis à ma mère ce que j'avais vécu...
                    elle me fie des grands yeux en me disant que c'était un
                    animal dangereux car il sécrété un poison urticant en cas de
                    danger.
                    <br />
                    <br />
                    C'est à ce moment là que j'ai compris que dans la nature il
                    n'y a rien de bon ni mauvais, la nature réagit juste en
                    cohérence avec le monde qui l'entoure, nous y compris.
                    <br />
                    <br /> Si j'avais était mal intentionné ou si j'avais voulu
                    la prendre pour l'emprisonnée chez moi elle n'aurait pas eu
                    la même réaction, là elle m'a laissée la prendre et la
                    sauver d'une mort quasi certaine sans rien me faire de mal.
                    <br />
                    <br /> Le choix de ce logo est en symbole avec ce moment
                    magique dans ma vie. Voilà pour l'histoire de ce logo.
                    <br />
                    <br /> Pour ce qui est du nom "Amano'Hilot", amano est un
                    clin d'oeil à mes origines latine paternelle, "a mano"
                    signifie "la main" et Hilot est une technique de soin par
                    massage, originaire des Philippines, que je pratique.
                  </p>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="store"
                  >
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 58 58"
                      space="preserve"
                    >
                      <g>
                        <polygon
                          fill="#F0785A"
                          points="2.5,11 2.5,21.592 2.5,29.619 2.5,58 7.5,58 56.5,58 56.5,21.592 56.5,11 	"
                        />
                        <rect
                          x="4.5"
                          y="29"
                          fill="#B35A5B"
                          width="28"
                          height="23"
                        />
                        <rect
                          x="6.5"
                          y="31"
                          fill="#B0D3F0"
                          width="24"
                          height="19"
                        />
                        <path
                          fill="#ED7161"
                          d="M15.5,11H1.878c-0.189,0-0.326-0.181-0.274-0.363l4.062-8.824C6.174,0.708,7.279,0,8.496,0H17.5
		L15.5,11z"
                        />
                        <polygon
                          fill="#ED7161"
                          points="43.5,11 29.5,11 29.5,0 41.5,0 	"
                        />
                        <path
                          fill="#F0C419"
                          d="M22.5,25L22.5,25c-3.866,0-7-3.134-7-7v-7h14v7C29.5,21.866,26.366,25,22.5,25z"
                        />
                        <path
                          fill="#F0C419"
                          d="M50.5,25L50.5,25c-3.866,0-7-3.134-7-7v-7h13.893c0.059,0,0.107,0.048,0.107,0.107V18
		C57.5,21.866,54.366,25,50.5,25z"
                        />
                        <polygon
                          fill="#F3D55B"
                          points="29.5,11 15.5,11 17.5,0 29.5,0 	"
                        />
                        <path
                          fill="#F3D55B"
                          d="M57.145,11H43.5l-2-11h9.004c1.217,0,2.322,0.708,2.831,1.814l4.068,8.845
		C57.451,10.83,57.323,11,57.145,11z"
                        />
                        <path
                          fill="#D36259"
                          d="M8.5,25L8.5,25c-3.866,0-7-3.134-7-7v-6.718C1.5,11.126,1.626,11,1.782,11H15.5v7
		C15.5,21.866,12.366,25,8.5,25z"
                        />
                        <path
                          fill="#D36259"
                          d="M36.5,25L36.5,25c-3.866,0-7-3.134-7-7v-7h14v7C43.5,21.866,40.366,25,36.5,25z"
                        />
                        <rect
                          x="36.5"
                          y="29"
                          fill="#B35A5B"
                          width="16"
                          height="29"
                        />
                        <rect
                          x="38.5"
                          y="31"
                          fill="#B0D3F0"
                          width="12"
                          height="25"
                        />
                        <path
                          fill="#FFFFFF"
                          d="M48.5,47c-0.553,0-1-0.447-1-1v-3c0-0.553,0.447-1,1-1s1,0.447,1,1v3C49.5,46.553,49.053,47,48.5,47
		z"
                        />
                        <g>
                          <path
                            fill="#FFFFFF"
                            d="M17.5,38c0.256,0,0.512-0.098,0.707-0.293l4-4c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-4,4c-0.391,0.391-0.391,1.023,0,1.414C16.988,37.902,17.244,38,17.5,38z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M17.5,43c0.256,0,0.512-0.098,0.707-0.293l2-2c0.391-0.391,0.391-1.023,0-1.414
			s-1.023-0.391-1.414,0l-2,2c-0.391,0.391-0.391,1.023,0,1.414C16.988,42.902,17.244,43,17.5,43z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M20.79,37.29c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.7
			c0.189,0.189,0.439,0.3,0.71,0.3c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71s-0.11-0.521-0.29-0.71
			C21.84,36.92,21.16,36.92,20.79,37.29z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M22.793,36.707C22.988,36.902,23.244,37,23.5,37s0.512-0.098,0.707-0.293l3-3
			c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3,3C22.402,35.684,22.402,36.316,22.793,36.707z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M25.793,37.293l-9,9c-0.391,0.391-0.391,1.023,0,1.414C16.988,47.902,17.244,48,17.5,48
			s0.512-0.098,0.707-0.293l9-9c0.391-0.391,0.391-1.023,0-1.414S26.184,36.902,25.793,37.293z"
                          />
                          <path
                            fill="#FFFFFF"
                            d="M29.21,35.29c-0.37-0.37-1.05-0.37-1.42,0c-0.181,0.189-0.29,0.45-0.29,0.71s0.109,0.52,0.29,0.71
			C27.979,36.89,28.24,37,28.5,37s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71S29.39,35.479,29.21,35.29z"
                          />
                        </g>
                        <path
                          fill="#CB8252"
                          d="M7.5,58h-6l-0.916-5.495C0.54,52.241,0.744,52,1.012,52H8.5L7.5,58z"
                        />
                        <rect
                          x="3.5"
                          y="41"
                          fill="#7F6E5D"
                          width="2"
                          height="11"
                        />
                        <path
                          fill="#24AE5F"
                          d="M8.688,36.68c0.304-0.768,0.521-1.625,0.357-2.444c-0.071-0.355-0.233-0.696-0.456-0.965
		c-0.376-0.455-0.54-1.109-0.411-1.711c0.122-0.57,0.049-1.21-0.216-1.721c-0.483-0.931-1.444-1.452-2.399-1.588
		c-0.702-0.1-1.477,0.007-1.997,0.543l-0.618,0.534c-0.702,0.331-1.24,1.064-1.394,1.897c-0.083,0.452-0.053,0.926,0.077,1.364
		c0.194,0.653,0.033,1.359-0.338,1.908c-0.836,1.236-1.057,3.007-0.441,4.409c0.078,0.178,0.179,0.354,0.297,0.513
		c0.404,0.543,0.556,1.263,0.387,1.944c-0.039,0.156-0.065,0.316-0.08,0.475c-0.066,0.727,0.082,1.455,0.232,2.167
		c0.156,0.74,0.348,1.539,0.888,2.003c0.732,0.629,1.792,0.385,2.63-0.047c1.184-0.611,2.28-1.617,2.698-2.997
		c0.244-0.805,0.186-1.739-0.141-2.495c-0.33-0.764-0.33-1.671,0.148-2.33C8.23,37.7,8.485,37.194,8.688,36.68z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <h2>L'entreprise Amano'Hilot !</h2>
                  </span>
                  <p className="subhead">
                    Amano'Hilot est une entreprise centrée sur le soin et le
                    bien-être depuis 2017 !
                    <br />
                    Au sein de cette établissement, je prodigue des soins Hilot,
                    mais pas seulement... <br />
                    <br />
                    Globalement, je concentre mon activité dans le soin à la
                    personne, mais aussi dans la promotion de la médecine
                    alternative et des activitées de bien-être.
                    <br />
                    <br /> J'anime une page facebook 'Amano-Hilot' ou je partage
                    des conseils pour être "un esprit sain dans un corps sain",
                    ceci par diverses types de publications, cette page compte
                    aujourd'hui plus de 100 000 mentions J'aime & environ 104
                    000 followers, ainsi qu'un groupe, 'Médecines alternatives'
                    qui lui compte environ 7000 membres sur le même réseau
                    social. <br />
                    <br /> Étant auteur également, j'ai réellement conscience de
                    la difficulté à passer ce "Parcour du Combattant" quand on
                    es un auteur qui souhaite se voir édité...
                    <br /> C'est pour cette raison que j'ai décidé de créer une
                    section d'assistance à l'auto-édition, afin de vous aider
                    dans vos démarches, voir même les prendre en charge à votre
                    place.
                  </p>
                </Fragment>
              )}
            </div>
          )}
        </div>
        {!isLittleScreen ? (
          <div className="toggleDrawerGames">
            <div className="drawerGamesHead" onClick={handleClickChevron}>
              <img className="car-games" src={car} alt="" />
              <h2 style={{ fontSize: 28 }}>Les jeux Amano'Hilot</h2>
              <img
                src={toggleActive ? ChevronW : Chevron}
                className={clickedChevron ? "chevron" : "chevron-open"}
                alt="chevron"
              />
            </div>
            <div className="drawerGames-content">
              <AmanoGames clickedChevron={clickedChevron} />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Presentation;
