import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { stripePaymentMethodHandler } from "./script";
import { useRecoilState } from "recoil";
import axios from "axios";
import sendOrderAtom from "../../../../stateManager/sendOrderAtom";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  const [sendOrder] = useRecoilState(sendOrderAtom);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [emailOrder, setEmailOrder] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const { setClickedChevron } = props;

  useEffect(() => {
    if (email.includes("@")) {
      setEmailOrder(sendOrder, email);
      const submitHandler = (e) => {
        axios.post(
          "https://sheet.best/api/sheets/ab15b15a-fa14-460b-bcf5-e4aed18738d9",
          emailOrder[0]
        );
        // .then((response) => {
        //   console.log(response);
        // });
      };
      submitHandler();
    }
  }, [email, emailOrder, sendOrder]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg("");

    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };
    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );

    stripePaymentMethodHandler(
      {
        result: paymentMethodResult,
        amount: props.amount,
      },
      handleResponse
    );
  };

  // callback method to handle the response
  const handleResponse = (response) => {
    setLoading(false);
    if (response.error) {
      setErrorMsg(
        typeof response.error === "string"
          ? response.error
          : response.error.message
      );
      return;
    }
    props.setPaymentCompleted(response.success ? true : false);
  };

  return (
    <React.Fragment>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <br />
        <br />
        <span className="text-muted">Paiement par carte bancaire</span>
      </h4>
      <form className="payment" onSubmit={handleSubmit}>
        <div className="row">
          <div className="headCartSection col-md-6 mb-3">
            <label htmlFor="cc-name">Nom du porteur de la carte</label>
            <input
              onClick={() => setClickedChevron(true)}
              id="cc-name"
              type="text"
              className="form-control inputOrderStep"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="headCartSection col-md-6 mb-3">
            <label htmlFor="cc-email">Votre Email</label>
            <input
              id="cc-email"
              type="text"
              className="form-control inputOrderStep"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Numéro de carte</label>
            <CardNumberElement
              id="cc-number"
              className="form-control inputOrderStep"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Date d'expiration</label>
            <CardExpiryElement
              id="expiry"
              className="form-control inputOrderStep"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control inputOrderStep"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <hr className="mb-4" />
        <button className="btn btn-dark w-100" type="submit" disabled={loading}>
          {loading ? (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="loading" role="status"></div>
              <sub style={{ fontSize: 12, color: "white", marginLeft: 15 }}>
                Patientez...
              </sub>
            </span>
          ) : (
            `PAYER ${props.amount}€`
          )}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}
