import { Fragment, useState } from "react";
import "./contact.css";
import ContactPict from "../../assets/bg-600.jpg";
import ContactPictDark from "../../assets/bg-600.png";
// import { useHistory } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ContactForm from "./contactForm/ContactForm";
import useMobile from "../../hooks/useMobile";
import ContactButton from "./contactButton/ContactButton";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const Contact = () => {
  // let history = useHistory();
  const { isMobile } = useMobile();
  const [openForm, setOpenForm] = useState(false);
  const [toggleActive] = useRecoilState(toggleActiveAtom);

  return (
    <Fragment>
      <div className="contact" id={isMobile ? "" : "contact"}>
        <img src={!toggleActive ? ContactPict : ContactPictDark} alt="" />
        <div className="contact-content">
          <div className="contact-text">
            <h2>Prendre contact</h2>
            <p id={isMobile ? "contact" : ""}>
              Donnez-moi de vos nouvelles, que vous ayez un livre à éditer, une
              question, ou un commentaire .
            </p>
          </div>
          {!isMobile ? (
            <span onClick={() => setOpenForm(true)}>
              <ContactButton setOpenForm={setOpenForm} />
            </span>
          ) : (
            <button
              className="contact-button btn draw-border"
              onClick={() => setOpenForm(true)}
              id="contact"
              style={{ margingTop: "25px" }}
            >
              Contactez-moi
            </button>
          )}
          <Modal
            classNames={{
              overlay: "customOverlay",
              modal: `${
                toggleActive ? "customModal customModal-dark" : "customModal"
              }`,
            }}
            open={openForm}
            onClose={() => setOpenForm(false)}
            center
          >
            <ContactForm toggleActive={toggleActive} />
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
