import { Fragment } from "react";
import { data } from "../data/data";
import { Zoom } from "react-awesome-reveal";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../../stateManager/toggleActiveAtom";

const NormalCard = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <Fragment>
      {data.map((res) => (
        <div
          key={res.id}
          className={toggleActive ? "card card-dark" : "card"}
          style={{ position: "relative" }}
        >
          <Zoom triggerOnce>
            <h1>
              {res.title} <br /> {res.title2}
            </h1>
          </Zoom>
          <Zoom>
            <span className="cards-parag">
              {res.extension ? (
                <Fragment>
                  <sub
                    style={{
                      position: "absolute",
                      marginTop: 10,
                      marginLeft: -16,
                      fontSize: 20,
                      fontStyle: "italic",
                    }}
                  >
                    *(
                  </sub>
                  <p style={{ fontStyle: "italic" }}>{res.description}</p>
                  <sub
                    className="author-parenthesis"
                    style={{
                      position: "absolute",
                      marginTop: -36,
                      right: 85,
                      fontSize: 20,
                      fontStyle: "italic",
                    }}
                  >
                    )
                  </sub>
                </Fragment>
              ) : (
                res.description
              )}
              {!res.extension ? (
                <Fragment>
                  <br />
                  <br />
                </Fragment>
              ) : null}
              {res.extension ? (
                <p>
                  {res.description2}
                  {res.description3}
                </p>
              ) : (
                res.description2
              )}
              {!res.extension ? <br /> : null}
              {res.extension ? null : res.description3}
              {!res.extension ? (
                <Fragment>
                  <br />
                  <br />
                </Fragment>
              ) : null}
              {res.description4}
            </span>
          </Zoom>
        </div>
      ))}
    </Fragment>
  );
};

export default NormalCard;
