import "./footer.css";
import LogoAnamo from "../../assets/logo-amano-hilot.png";
import LogoAnamoDark from "../../assets/logo-amano-hilot-dark.png";
import RedBubble from "../../assets/redBubble.png";
import Zazzle from "../../assets/images/zazzle.png";
import TheBookEdition from "../../assets/images/theBookEdition.png";
import MonSite from "../../assets/perso.png";
import useMobile from "../../hooks/useMobile";
import { Rotate, JackInTheBox, Bounce } from "react-awesome-reveal";
import ModalMentions from "../mentions-legales/ModalMentions";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import { Fragment } from "react";

const Footer = () => {
  const { isMobile } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);

  return (
    <div className={toggleActive ? "footer footer-dark" : "footer"}>
      <div className="logoAmano">
        <JackInTheBox>
          {isMobile ? (
            <img className="footer-logo" src={LogoAnamoDark} alt="" />
          ) : (
            <img className="footer-logo" src={LogoAnamo} alt="" />
          )}
        </JackInTheBox>
        <div className="socialIcons">
          <ul className="social-icons">
            <li>
              <Rotate triggerOnce>
                <a
                  href="https://www.instagram.com/rodolphelakotaspirit/?hl=fr"
                  target="new"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </Rotate>
            </li>
            <li>
              <Rotate triggerOnce>
                <a href="https://www.facebook.com/amanohilot" target="new">
                  <i className="fa fa-facebook"></i>
                </a>
              </Rotate>
            </li>
            <li>
              <Rotate triggerOnce>
                <a
                  href="https://www.linkedin.com/in/rodolphe-augusto-d%C3%A9veloppeur-fullstack-react-node-1a20b759/"
                  target="new"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </Rotate>
            </li>
          </ul>
          <a
            href="https://www.redbubble.com/people/rodolphe-a/shop?asc=u"
            target="new"
            className="redbubbleLink"
          >
            <Rotate triggerOnce>
              <img className="redBubble" src={RedBubble} alt="" />
            </Rotate>
          </a>
          <a
            href="https://www.zazzle.fr/store/amano_hilot_shop/products"
            target="new"
            className="redbubbleLink"
          >
            <Rotate triggerOnce>
              <img
                style={{ width: "110%", marginBottom: -7, marginLeft: -5 }}
                className="redBubble"
                src={Zazzle}
                alt=""
              />
            </Rotate>
          </a>
          <a
            href="https://www.thebookedition.com/fr/35750_rodolphe-augusto"
            target="new"
            className="redbubbleLink"
          >
            <Rotate triggerOnce>
              <img
                style={{ width: "110%", marginBottom: -7, marginLeft: 0 }}
                className="redBubble"
                src={TheBookEdition}
                alt=""
              />
            </Rotate>
          </a>

          <div className="monSite-container">
            <Rotate triggerOnce>
              <a href="https://www.rodolphe-augusto.fr/" target="new">
                <img className="monSite" src={MonSite} alt="" />
              </a>
            </Rotate>
          </div>
        </div>
      </div>
      <div className="list-unstyled text-small">
        <br />
        <iframe
          title="facebook"
          className="facebook-footer"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Famanohilot%2F&tabs=timeline&width=250&height=120&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          width="250"
          height="125"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <ModalMentions />
      <Bounce triggerOnce>
        <div className="copyright">
          <p>
            Copyright <span className="copyrightLogo">©</span> 2021 Tous droits
            réservés. - développé avec
            <span className="capsule">
              <svg className="heart" viewBox="0 0 32 29.6">
                <path
                  d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                />
              </svg>
            </span>{" "}
            par Rodolphe Augusto
          </p>
        </div>
      </Bounce>
      {isMobile && (
        <Fragment>
          <br />
          <br />
        </Fragment>
      )}
    </div>
  );
};

export default Footer;
