import { useState } from "react";
import { dataPictures } from "./dataPictures";
import "./slideShowPictures.scss";

const SlideShowPictures = () => {
  const [playing, setPlaying] = useState(false);

  const TogglePlaying = () => {
    if (playing) {
      setPlaying(false);
    } else {
      setPlaying(true);
    }
  };
  return (
    <div className={playing ? "body-pictures" : "body-pictures paused"}>
      <div className="switcherPictures">
        {dataPictures.map((res) => (
          <div key={res.id} className="switcherPictures__item">
            <img src={res.pict} alt="" />
          </div>
        ))}
      </div>
      <button className="button-pictures" onClick={TogglePlaying}>
        {playing ? "pause" : "start"}
      </button>
    </div>
  );
};

export default SlideShowPictures;
