import "./index.css";
// import "./antAnimation.css";
import Logo from "./img/logo-lapid-art.png";
import Stickers from "./img/sticker.svg";
import Attente from "./img/Attente.svg";

const Lapidart = () => {
  return (
    <div className="body-lapidart">
      <div className="lapidart-container">
        <div className="global-content-lapidart">
          <header className="attente">
            <img style={{ marginTop: 24 }} src={Logo} alt="logo" />
          </header>
          <div className="content-lapidart">
            <span>
              <h1 className="titre-attente">
                Site en cours de développement... <br />
                Installez-vous confortablement, ça arrive dans quelques*
                instants.
              </h1>
              <br />
              <div className="subtitle">
                <sup>
                  *entre 8 secondes et 4 jours ou 5... ou 6... bientôt !
                </sup>
              </div>
            </span>
            <div className="sticker">
              <img src={Stickers} alt="sticker" />
              <div className="message">
                Si vous êtes quelqu'un de très pressé consultez ma page Facebook
                en attentant
              </div>
              <div className="link-message">
                <a
                  href="https://www.facebook.com/LapidArt-173291856624985"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Lapid'Art
                </a>
              </div>
            </div>
          </div>
        </div>
        <footer className="sol">
          <div className="plinte"></div>
          <div className="content-lapidart-footer">
            <div className="ants" style={{ marginTop: -60 }}>
              <div className="wrapper-ant-anim">
                <div className="box-canvas">
                  <div className="scene-wrapper">
                    <div className="ant-wrapper one">
                      <div className="leaf"></div>
                      <div className="body-left"></div>
                      <div className="body-middle"></div>
                      <div className="body-right"></div>
                    </div>

                    <div className="ant-wrapper two">
                      <div className="leaf"></div>
                      <div className="body-left"></div>
                      <div className="body-middle"></div>
                      <div className="body-right"></div>
                    </div>

                    <div className="ant-wrapper three">
                      <div className="leaf"></div>
                      <div className="body-left"></div>
                      <div className="body-middle"></div>
                      <div className="body-right"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={Attente} alt="Attente" />
            <p className="making">
              Copyright © 2021 -
              <a href="https://amano-hilot.fr" target="new">
                {" "}
                Amano'Hilot
              </a>{" "}
              - making with 💜 by{" "}
              <a
                href="https://rodolphe-augusto.fr"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Rodolphe Augusto
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Lapidart;
