import useMobile from "../../hooks/useMobile";
import "./headerPhone.css";

const HeaderPhone = () => {
  const { isMobile } = useMobile();

  return (
    <div className="headerPhone">
      {isMobile ? (
        <div>
          <iframe
            title="sharing button"
            className="sharing-button"
            src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.amano-hilot.fr&layout=box_count&size=small&width=81&height=40&appId"
            width="81"
            height="40"
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      ) : null}
    </div>
  );
};

export default HeaderPhone;
