import React, { useState } from "react";
import styled from "styled-components";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";
import "./bottomNavigation.css";
import useMobile from "../../hooks/useMobile";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { Bounce } from "react-awesome-reveal";

const BottomNavigation = () => {
  const { isMobile } = useMobile();
  const [homeLink, setHomeLink] = useState(false);
  const [booksLink, setBooksLink] = useState(false);
  const [productsLink, setProductsLink] = useState(false);
  const [aboutLink, setAboutLink] = useState(false);
  const [authorLink, setAuthorLink] = useState(false);
  const [contactLink, setContactLink] = useState(false);

  const handleChangeHome = () => {
    if (homeLink !== true) {
      setHomeLink(true);
      setBooksLink(false);
      setAboutLink(false);
      setAuthorLink(false);
      setContactLink(false);
      setProductsLink(false);
    }
  };
  const handleChangeBooks = () => {
    if (booksLink !== true) {
      setHomeLink(false);
      setBooksLink(true);
      setAboutLink(false);
      setAuthorLink(false);
      setContactLink(false);
      setProductsLink(false);
    }
  };
  const handleChangeProducts = () => {
    if (productsLink !== true) {
      setHomeLink(false);
      setBooksLink(false);
      setAboutLink(false);
      setAuthorLink(false);
      setContactLink(false);
      setProductsLink(true);
    }
  };
  const handleChangeAbout = () => {
    if (aboutLink !== true) {
      setHomeLink(false);
      setBooksLink(false);
      setAboutLink(true);
      setAuthorLink(false);
      setContactLink(false);
      setProductsLink(false);
    }
  };
  const handleChangeAuthor = () => {
    if (authorLink !== true) {
      setHomeLink(false);
      setBooksLink(false);
      setAboutLink(false);
      setAuthorLink(true);
      setContactLink(false);
      setProductsLink(false);
    }
  };
  const handleChangeContact = () => {
    if (contactLink !== true) {
      setHomeLink(false);
      setBooksLink(false);
      setAboutLink(false);
      setAuthorLink(false);
      setContactLink(true);
      setProductsLink(false);
    }
  };

  return (
    <Styles.Wrapper>
      {isMobile ? (
        <MobileNavbar.Wrapper className="navWrapper">
          <MobileNavbar.Items>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#home"
                  onClick={handleChangeHome}
                >
                  {homeLink ? <HomeIcon className="selected" /> : <HomeIcon />}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: 3,
                      marginTop: 2,
                    }}
                  >
                    Accueil
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#books"
                  onClick={handleChangeBooks}
                >
                  {booksLink ? (
                    <MenuBookIcon className="selected" />
                  ) : (
                    <MenuBookIcon />
                  )}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: -8,
                      width: 52,
                    }}
                  >
                    Mes Livres
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#derivated"
                  onClick={handleChangeProducts}
                >
                  {productsLink ? (
                    <ShoppingBasketIcon className="selected" />
                  ) : (
                    <ShoppingBasketIcon />
                  )}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: -8,
                      width: 52,
                    }}
                  >
                    Produits
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#about"
                  onClick={handleChangeAbout}
                >
                  {aboutLink ? <InfoIcon className="selected" /> : <InfoIcon />}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: 8,
                    }}
                  >
                    Infos
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#author"
                  onClick={handleChangeAuthor}
                >
                  {authorLink ? (
                    <CreateIcon className="selected" />
                  ) : (
                    <CreateIcon />
                  )}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: -2,
                    }}
                  >
                    Auteurs
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
            <MobileNavbar.Item>
              <Bounce triggerOnce>
                <a
                  style={{ position: "relative" }}
                  href="#contact"
                  onClick={handleChangeContact}
                >
                  {contactLink ? (
                    <EmailIcon className="selected" />
                  ) : (
                    <EmailIcon />
                  )}
                  <label
                    style={{
                      position: "absolute",
                      bottom: -6,
                      left: 3,
                    }}
                  >
                    Contact
                  </label>
                </a>
              </Bounce>
            </MobileNavbar.Item>
          </MobileNavbar.Items>
        </MobileNavbar.Wrapper>
      ) : null}
    </Styles.Wrapper>
  );
};

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: #eeeeee;
    height: 100vh;
    position: absolute;
    /* width: 100%; */
  `,
};
const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    align-self: flex-start;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    margin-left: 5vw;
    margin-right: 28px;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `,
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 0px 11px grey;
    height: 65px;
    justify-content: center;
    z-index: 99;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0 2rem;

    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)``,
};

export default BottomNavigation;
