import useMobile from "../../hooks/useMobile";
import "./author.css";
import { data } from "./data/data";
import { Fragment } from "react";
import PerspectiveCards from "./perspectiveCards/PerspectiveCards";
import NormalCard from "./normalCards/NormalCard";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const Author = () => {
  const { isMobile, isLittleScreen } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  // console.log(isLittleScreen);
  return (
    <Fragment>
      <div className={toggleActive ? "author-dark" : "author"}>
        <br />
        <h2 className="titleAuteur" id="author">
          Vous êtes auteurs !{" "}
        </h2>
        {!isMobile ? <hr size="1" className="author-hr roundedHr" /> : null}
        <h2 className="h2-mobile">
          "Vous êtes auteur(e)s, et vous souhaitez auto-éditer une oeuvre?
          <br />
          {data.length}{" "}
          <sub
            style={{
              fontSize: 12,
              position: "absolute",
              marginLeft: -9,
              fontStyle: "italic",
            }}
          >
            *
          </sub>{" "}
          Choix s'offrent à vous ! "
        </h2>

        <div
          className={
            !isMobile && !isLittleScreen ? "author-cardInherit" : "author-cards"
          }
        >
          {isMobile || isLittleScreen ? <NormalCard /> : <PerspectiveCards />}
        </div>
        <span className="spanPhone" style={{ fontWeight: 600, fontSize: 18 }}>
          <b>
            Toutes ces offres rendent vos oeuvres disponibles à la commande sur
            le site de l'imprimeur... Mais aussi disponibles à la commande, avec
            le numéro ISBN (International serial book number), dans toutes les
            librairies Françaises et francophone.
          </b>
        </span>
      </div>
    </Fragment>
  );
};

export default Author;
