/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./ebooksSection.css";
import { dataEbooks } from "./dataEbooks";
import addCart from "../../../assets/15949192821556258219.svg";
import done from "../../../assets/5474772501556258238.svg";
import remove from "../../../assets/15008606611541068764.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import shoppingCartContentAtom from "../../../stateManager/shoppingCartContentAtom";

const EbooksSection = () => {
  const [buyClick1, setBuyClick1] = useState(false);
  const [buyClick2, setBuyClick2] = useState(false);
  const [buyClick3, setBuyClick3] = useState(false);
  const [remove1, setRemove1] = useState(false);
  const [remove2, setRemove2] = useState(false);
  const [remove3, setRemove3] = useState(false);
  const [handleCart, setHandleCart] = useRecoilState(shoppingCartContentAtom);
  // console.log("cart content", handleCart);

  useEffect(() => {
    function loadBasketSaved() {
      if (localStorage.getItem("basket") !== null) {
        const savedBasket = localStorage.getItem("basket");
        setHandleCart(JSON.parse(savedBasket));
      }
    }
    loadBasketSaved();
  }, []);

  const toggleRemove1 = () => {
    const idToRemove = 0;
    setRemove1(true);
    setHandleCart(handleCart.filter((item) => item.id !== idToRemove));
  };
  const toggleRemove2 = () => {
    const idToRemove = 1;
    setRemove2(true);
    setHandleCart(handleCart.filter((item) => item.id !== idToRemove));
  };
  const toggleRemove3 = () => {
    const idToRemove = 2;
    setRemove3(true);
    setHandleCart(handleCart.filter((item) => item.id !== idToRemove));
  };

  useEffect(() => {
    if (handleCart.length > 0) {
      localStorage.setItem("basket", JSON.stringify(handleCart, ...handleCart));
    }
    if (handleCart.length === 0) {
      localStorage.removeItem("basket");
    }
  }, [handleCart]);

  const handleProduct1ToBasket = () => {
    setHandleCart([
      ...handleCart,
      {
        id: dataEbooks[0].id,
        pict: dataEbooks[0].img,
        name: `${dataEbooks[0].name}`,
        price: dataEbooks[0].promoPrice,
      },
    ]);
  };
  const handleProduct2ToBasket = () => {
    setHandleCart([
      ...handleCart,
      {
        id: dataEbooks[1].id,
        pict: dataEbooks[1].img,
        name: `${dataEbooks[1].name}`,
        price: dataEbooks[1].promoPrice,
      },
    ]);
  };
  const handleProduct3ToBasket = () => {
    setHandleCart([
      ...handleCart,
      {
        id: dataEbooks[2].id,
        pict: dataEbooks[2].img,
        name: `${dataEbooks[2].name}`,
        price: dataEbooks[2].promoPrice,
      },
    ]);
  };

  useEffect(() => {
    if (handleCart.length === 0) {
      setBuyClick1(false);
      setBuyClick2(false);
      setBuyClick3(false);
    }
  }, [handleCart]);

  return (
    <div className="wrapper-ebooks">
      <div className="wrapper-ebooksContent">
        <div key={dataEbooks[0].id} className="container-ebooks">
          <div className="top">
            <img src={dataEbooks[0].img} alt="ebooks" />{" "}
          </div>

          <div className={buyClick1 ? "bottom clicked" : "bottom"}>
            <div className="left">
              <div className="details">
                <h1>{dataEbooks[0].name}</h1>
                <span className="ebooksPrice-content">
                  <p>{dataEbooks[0].price}0€</p>
                  <b>{dataEbooks[0].promoPrice}€</b>
                </span>
              </div>
              <div
                className="buy"
                onClick={() => {
                  setBuyClick1(true);
                  handleProduct1ToBasket();
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  className="material-icons"
                  src={addCart}
                  alt="cart"
                />
              </div>
            </div>
            <div className="right">
              <div className="done">
                <img
                  style={{ width: "40%", marginTop: 37 }}
                  src={done}
                  alt="done"
                  className="material-icons"
                />
              </div>
              <div className="details">
                <h1>{dataEbooks[0].name}</h1>
                <p>Ajouté à votre panier</p>
              </div>
              <div
                className="remove"
                onClick={() => {
                  toggleRemove1();
                  setBuyClick1(false);
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  src={remove}
                  alt="remove"
                  className="material-icons"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inside">
          <div className="icon">
            <i className="fas fa-info-circle"></i>
          </div>
          <div style={{ color: "white" }} className="contents">
            {dataEbooks[0].resume}
          </div>
        </div>
      </div>
      <div className="wrapper-ebooksContent">
        <div key={dataEbooks[1].id} className="container-ebooks">
          <div className="top">
            <img src={dataEbooks[1].img} alt="ebooks" />{" "}
          </div>

          <div className={buyClick2 ? "bottom clicked" : "bottom"}>
            <div className="left">
              <div className="details">
                <h1>{dataEbooks[1].name}</h1>
                <span className="ebooksPrice-content">
                  <p>{dataEbooks[1].price}0€</p>
                  <b>{dataEbooks[1].promoPrice}€</b>
                </span>
              </div>
              <div
                className="buy"
                onClick={() => {
                  setBuyClick2(true);
                  handleProduct2ToBasket();
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  className="material-icons"
                  src={addCart}
                  alt="cart"
                />
              </div>
            </div>
            <div className="right">
              <div className="done">
                <img
                  style={{ width: "40%", marginTop: 37 }}
                  src={done}
                  alt="done"
                  className="material-icons"
                />
              </div>
              <div className="details">
                <h1>{dataEbooks[1].name}</h1>
                <p>Ajouté à votre panier</p>
              </div>
              <div
                className="remove"
                onClick={() => {
                  toggleRemove2();
                  setBuyClick2(false);
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  src={remove}
                  alt="remove"
                  className="material-icons"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inside">
          <div className="icon">
            <i className="fas fa-info-circle"></i>
          </div>
          <div style={{ color: "white" }} className="contents">
            {dataEbooks[1].resume}
          </div>
        </div>
      </div>
      <div className="wrapper-ebooksContent">
        <div key={dataEbooks[2].id} className="container-ebooks">
          <div className="top">
            <img src={dataEbooks[2].img} alt="ebooks" />{" "}
          </div>

          <div className={buyClick3 ? "bottom clicked" : "bottom"}>
            <div className="left">
              <div className="details">
                <h1>{dataEbooks[2].name}</h1>
                <span className="ebooksPrice-content">
                  <p>{dataEbooks[2].price}0€</p>
                  <b>{dataEbooks[2].promoPrice}€</b>
                </span>
              </div>
              <div
                className="buy"
                onClick={() => {
                  setBuyClick3(true);
                  handleProduct3ToBasket();
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  className="material-icons"
                  src={addCart}
                  alt="cart"
                />
              </div>
            </div>
            <div className="right">
              <div className="done">
                <img
                  style={{ width: "40%", marginTop: 37 }}
                  src={done}
                  alt="done"
                  className="material-icons"
                />
              </div>
              <div className="details">
                <h1>{dataEbooks[2].name}</h1>
                <p>Ajouté à votre panier</p>
              </div>
              <div
                className="remove"
                onClick={() => {
                  toggleRemove3();
                  setBuyClick3(false);
                }}
              >
                <img
                  style={{ width: "37%", marginTop: 34 }}
                  src={remove}
                  alt="remove"
                  className="material-icons"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inside">
          <div className="icon">
            <i className="fas fa-info-circle"></i>
          </div>
          <div style={{ color: "white" }} className="contents">
            {dataEbooks[2].resume}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EbooksSection;
