import { Parallax, Background } from "react-parallax";
import bgDark from "../../assets/ulSlnDG.png";
import bg from "../../assets/ulSlnDG.jpg";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const Container = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <Parallax className="parallaxClasse" strength={850}>
      <Background className={toggleActive ? "custom-bg left" : "custom-bg"}>
        <img src={toggleActive ? bgDark : bg} alt="fill murray" />
      </Background>
      <span
        className={
          toggleActive
            ? "edition-content edition-content-dark"
            : "edition-content black"
        }
      >
        <h2>
          Quels genres de livres Peuvent être pris en charges par Amano'Hilot?
        </h2>
        {/*<hr size="1" className="editor-hr roundedHr" />*/}
        <h3>
          Je cible essentiellement les oeuvres faisant parties des catégories
          suivantes :
        </h3>
        <b>
          <br />
          <ul>
            <li>de la santé, </li>
            <li>du sport,</li>
            <li>du bien-être,</li>
            <li>ou encore des hobbies.</li>
          </ul>
          <br />
          Cela peut-être des livres techniques sur des sujets particuliers, des
          encyclopédies ou des méthodologies, ou encore des ouvrages contenants
          des conseils ou astuces dans les domaines cités plus haut.
        </b>
        <p>
          Vous pratiquez un sport, une activité bien-être ou êtes doué dans
          l'expression d'une passion ! Vous souhaitez faire partager vos
          connaîssances aux autres, transmettre une technique ou un savoir que
          vous avez développé ou appris... Le but de ma démarche est de
          promouvoir la prise de conscience du bien-être de chacun, et donc,
          toutes les oeuvres qui contribuent à ce sens valent d'être éditées et
          misent à disposition du public.
          <br /> Bien sur, dans cette démarche, tous les droits et copyright de
          vos oeuvres vous appartiennent... <br />
          Amano'Hilot n'agit que entant qu'éditeur, mandaté par l'auteur, auprés
          des organismes nécessaires à la publication et l'édition (selon
          l'option que vous avez choisi, dans l'option simple cette démarche
          n'est pas prise en charge par Amano'Hilot).
        </p>
      </span>
    </Parallax>
  );
};

export default Container;
