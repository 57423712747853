import { useRecoilState } from "recoil";
import clickedOpenCartAtom from "../../../../stateManager/clickedOpenCartAtom";
import shoppingCartContentAtom from "../../../../stateManager/shoppingCartContentAtom";
import ShoppingCart from "../shoppingCart/ShoppingCart";
import "./shoppingBadge.css";

const ShoppingBadge = () => {
  const [openShoppingCart, setOpenShoppingCart] =
    useRecoilState(clickedOpenCartAtom);
  const [shoppingCartContent] = useRecoilState(shoppingCartContentAtom);

  const handleClickOpenCart = () => {
    if (openShoppingCart) {
      setOpenShoppingCart(false);
    }
    if (!openShoppingCart) {
      setOpenShoppingCart(true);
    }
  };

  // console.log("clicked cart", openShoppingCart);
  return (
    <span style={{ position: "relative" }}>
      {openShoppingCart && <ShoppingCart />}
      <div className="shopping-badge" onClick={handleClickOpenCart}>
        <div id="ex3">
          <span
            className="p1 fa-stack fa-2x has-badge"
            data-count={shoppingCartContent.length}
          >
            <i
              style={{ color: "#8537cc" }}
              className="p2 fa fa-circle fa-stack-2x"
            ></i>
            <i
              className="p3 fa fa-shopping-cart fa-stack-1x fa-inverse"
              data-count="5"
            ></i>
          </span>
        </div>
      </div>
    </span>
  );
};

export default ShoppingBadge;
