import "./header.css";
import LogoAnamo from "../../assets/logo-amano-hilot.png";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import useMobile from "../../hooks/useMobile";

const Header = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  const { isMobile } = useMobile();
  return (
    <div className={toggleActive && !isMobile ? "Header-dark" : "Header"}>
      <img
        style={{ marginTop: 4, width: 79 }}
        className="logo"
        src={LogoAnamo}
        alt=""
      />
      <div>
        <iframe
          title="sharing button"
          className="sharing-button"
          src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2F.amano-hilot.fr&layout=box_count&size=small&width=81&height=40&appId"
          width="81"
          height="40"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>
      <div className="menu-bloc">
        <h2>
          <a href="#home">Accueil</a>
        </h2>
        <h2>
          <a href="#amano">Qu'est-ce-que Amano'Hilot?</a>
        </h2>
        <h2>
          <a href="#books">Mes Livres</a>
        </h2>
        <h2>
          <a href="#derivated">Produits dérivés</a>
        </h2>
        <h2>
          <a href="#about">Quels Livres éditer?</a>
        </h2>
        <h2>
          <a href="#author">Vous êtes auteur?</a>
        </h2>
        <h2>
          <a href="#contact">Contact</a>
        </h2>
      </div>
    </div>
  );
};

export default Header;
