// Dummy data
const tabData = [
  [
    {
      name: "Le principe",
      content:
        "Le principe -> Si je commande par lot de 12 livres, le prix de chaque livre se trouve réduit de 5€, donc, par exemple, le Tome 1 est à 40.50€ tarif à la demande, acheté de façon groupée, il sera à 35.50€.",
    },
    {
      name: "Condition",
      content:
        "Condition -> La progressivité contraint à commencer par le tome 1 de YogAlmanach uniquement, afin que l'offre soit régulière et renouvelable, donc cette offre ne s'applique que pour le Tome 1 pour le moment.",
    },
    {
      name: "Comment faire ?",
      content: [
        "Comment faire ? -> Vous me contactez par le biais du formulaire de contact présent plus bas sur ce site (n'oubliez pas d'indiquer votre adresse postale en plus de votre adresse mail.)",
        " Ensuite, lors de ma réponse, je demanderais confirmation de votre souhait d'aquerir le tome 1 ainsi que de votre adresse mail et postale fournit, à partir de là, je vous mettrais sur une liste d'attente.",
        " Une fois le nombre de personne suffisant pour passer commande atteint, vous aurez la possibilité de régler (35.50€ + frais de port) soit par virement bancaire ou bien éventuellement par paypal. Vous ne payez rien avant que le nombre de personne ne soit atteint!",
        " Ceci fait, je vous informerais de chaque étape (la fabrication, la réception des livres venant de mon imprimeur, puis l'envoi du livre vers votre adresse).",
      ],
    },
    {
      name: "Le Délai",
      content:
        "Le Délai -> Dès que le nombre de personnes ayant commandées atteint 12, je passe la commande, en tenant compte du temps de fabrication, de l'envoi des livres par l'imprimeur vers moi, puis de l'envoi de moi vers vous, je compte raisonnablement un mois, mais ça peut-être beaucoup moins selon le nombre de personnes désireuses d'avoir ce livre.",
    },
  ],
];

export default tabData;
