import "./books.css";
import { Fade } from "react-awesome-reveal";
import { Fragment, useState } from "react";
import Card from "./Cards/Card";
import useMobile from "../../hooks/useMobile";
import TabPages from "./tabs";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import OffertPdf from "./offertPdf/OffertPdf";
import Chevron from "../../assets/8347136151582545590-128.png";
import EbooksSection from "./ebooksSection/EbooksSection";
import TheBookEdition from "../../assets/images/plume.png";

const Books = () => {
  const { isMobile } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  const [clickedChevron1, setClickedChevron1] = useState(false);
  const [clickedChevron2, setClickedChevron2] = useState(false);
  const [clickedChevron3, setClickedChevron3] = useState(false);
  const [clickedChevron4, setClickedChevron4] = useState(false);

  const handleClickChevron1 = () => {
    if (clickedChevron1) {
      setClickedChevron1(false);
    }
    if (!clickedChevron1) {
      setClickedChevron1(true);
      setClickedChevron2(false);
      setClickedChevron3(false);
      setClickedChevron4(false);
    }
  };
  const handleClickChevron2 = () => {
    if (clickedChevron2) {
      setClickedChevron2(false);
    }
    if (!clickedChevron2) {
      setClickedChevron2(true);
      setClickedChevron1(false);
      setClickedChevron3(false);
      setClickedChevron4(false);
    }
  };
  const handleClickChevron3 = () => {
    if (clickedChevron3) {
      setClickedChevron3(false);
    }
    if (!clickedChevron3) {
      setClickedChevron3(true);
      setClickedChevron2(false);
      setClickedChevron1(false);
      setClickedChevron4(false);
    }
  };
  const handleClickChevron4 = () => {
    if (clickedChevron4) {
      setClickedChevron4(false);
      setClickedChevron3(false);
    }
    if (!clickedChevron4) {
      setClickedChevron4(true);
      setClickedChevron1(false);
      setClickedChevron2(false);
    }
  };

  return (
    <Fragment>
      <div className={toggleActive ? "books-dark" : "books"}>
        <div className="books-head-content">
          <h2 className="books-h2" style={{ paddingTop: 20 }} id="books">
            Mes livres
          </h2>
          {!isMobile ? <hr size="1" className="books-hr roundedHr" /> : null}
          <br />
          <h3>Une collection ciblée sur le bien-être et la santé!!</h3>
          <br />
        </div>
        <Fade triggerOnce>
          <div className="books-content">
            <hr style={{ width: "100%" }} />
            <h1>Acheter les versions papier.</h1>
            <b
              style={{
                position: "relative",
                lineHeight: "3rem",
                color: "#bd3636",
              }}
            >
              {`${
                isMobile
                  ? "Pressez sur la couverture qui vous intéresse puis"
                  : "Survolez la couverture qui vous intéresse puis"
              } ${isMobile ? "Pressez  sur la plume" : "cliquez sur la plume"}`}
              &nbsp;
              <img className="plumeLogo" src={TheBookEdition} alt="" />. <br />
              Cela vous conduira vers le site de l'imprimeur, avec possibilité
              de voir le verso, un extrait du livre ainsi que l'achat direct de
              celui-ci.
            </b>
            <br />
            <Card />
          </div>
        </Fade>
        <div className="books-contentText">
          <br />
          <div className="ebooksShopping">
            <div className="titleShopping">
              <hr style={{ width: "100%" }} />
              <h1>Acheter les versions Ebook (pdf)</h1>
            </div>
            <div className="ebooks_Section">
              <span className="promoText-content">
                <b>
                  Pour fêter l'arrivée de cette section sur le site... &ensp;
                  <p>25% &nbsp;</p> de réduction sur tous les e-books en ce
                  moment !!
                </b>
              </span>
              <EbooksSection />
            </div>
          </div>
          <br />
          {isMobile && <hr style={{ color: "lightgray", opacity: "0.6" }} />}
          <br />
          <hr style={{ width: "100%" }} />
          <h2>Ebook gratuit en Période limitée !</h2>
          <OffertPdf />
          <br />
          {isMobile && <hr style={{ color: "lightgray", opacity: "0.6" }} />}
          <br />
          <h2>Possibilité de commande groupée</h2>
          {!isMobile ? (
            <hr size="1" className="booksOrder-hr roundedHr" />
          ) : null}
          <p style={{ textAlign: "left" }}>
            Ayant conscience qu'en tant qu'auteur auto-édité avec un service
            d'impression à la demande, le prix de certains de mes livres est un
            peu plus onéreux que par une distribution classique, qui en commande
            par lot de 1000 ou 10 000 exemplaires. Le système n'avantage pas
            l'auteur qui souhaite s'auto-éditer, néanmoins... mon imprimeur me
            fait bénéficier de réduction selon la quantité commandée. J'ai donc
            décidé de vous faire profiter de cette possibilité en regroupant les
            commandes des personnes qui le souhaitent.
          </p>
          <sub
            style={{
              fontSize: 14,
              fontWeight: "bold",
              fontStyle: "italic",
              color: "rgb(85 26 139)",
              fontFamily: "Karla, Helvetica, Arial, Lucida, sans-serif",
              textAlign: "left !important",
            }}
          >
            Entre parenthèses, une dédicace est possible en achetant par cette
            voie, les livres passeront tous entre mes mains, donc il n'y a pas
            de soucis à vous faire une petite dédicace en passant.
          </sub>
          <br />
          <br />
          {isMobile ? (
            <div>
              <div
                className={
                  clickedChevron1
                    ? "principle-step principle-step-open"
                    : "principle-step"
                }
              >
                <div className="principle-head" onClick={handleClickChevron1}>
                  <h2>Le principe ! </h2>
                  <img
                    src={Chevron}
                    className={
                      clickedChevron1
                        ? "chevron-groupedOrder-open"
                        : "chevron-groupedOrder"
                    }
                    alt="chevron"
                  />
                </div>
                <div className="principle-content">
                  <p style={{ textAlign: "left" }}>
                    Si je commande par lot de 12 livres, le prix de chaque livre
                    se trouve réduit de 5€, donc, par exemple, le Tome 1 est à
                    40.50€ tarif à la demande, acheté de façon groupée, il sera
                    à 35.50€.
                  </p>
                </div>
              </div>{" "}
              <div
                className={
                  clickedChevron2
                    ? "conditions-step conditions-step-open"
                    : "conditions-step"
                }
              >
                <div className="conditions-head" onClick={handleClickChevron2}>
                  <h2>Condition ! </h2>
                  <img
                    src={Chevron}
                    className={
                      clickedChevron2
                        ? "chevron-groupedOrder-open"
                        : "chevron-groupedOrder"
                    }
                    alt="chevron"
                  />
                </div>
                <div className="conditions-content">
                  <p style={{ textAlign: "left" }}>
                    Condition -&gt; La progressivité contraint à commencer par
                    le tome 1 de YogAlmanach uniquement, afin que l'offre soit
                    régulière et renouvelable, donc cette offre ne s'applique
                    que pour le Tome 1 pour le moment.{" "}
                  </p>
                </div>
              </div>
              <div
                className={
                  clickedChevron3 ? "howTo-step howTo-step-open" : "howTo-step"
                }
              >
                <div className="howTo-head" onClick={handleClickChevron3}>
                  <h2>Comment faire ?</h2>
                  <img
                    src={Chevron}
                    className={
                      clickedChevron3
                        ? "chevron-groupedOrder-open"
                        : "chevron-groupedOrder"
                    }
                    alt="chevron"
                  />
                </div>
                <div className="howTo-content">
                  <ul
                    style={{
                      marginTop: 10,
                      textAlign: "left",
                      fontFamily: "Karla, Helvetica, Arial, Lucida, sans-serif",
                    }}
                  >
                    <li>
                      1.\ Vous me contactez par le biais du formulaire de
                      contact présent plus bas sur ce site (n'oubliez pas
                      d'indiquer votre adresse postale en plus de votre adresse
                      mail.)
                    </li>
                    <li>
                      2.\ Ensuite, lors de ma réponse, je demanderais
                      confirmation de votre souhait d'aquerir le tome 1 ainsi
                      que de votre adresse mail et postale fournit, à partir de
                      là, je vous mettrais sur une liste d'attente.
                    </li>
                    <li>
                      3.\ Une fois le nombre de personne suffisant pour passer
                      commande atteint, vous aurez la possibilité de régler
                      (35.50€ + frais de port) soit par virement bancaire ou
                      bien éventuellement par paypal. Vous ne payez rien avant
                      que le nombre de personne ne soit atteint!
                    </li>
                    <li>
                      4.\ Ceci fait, je vous informerais de chaque étape (la
                      fabrication, la réception des livres venant de mon
                      imprimeur, puis l'envoi du livre vers votre adresse).
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={
                  clickedChevron4 ? "delai-step delai-step-open" : "delai-step"
                }
              >
                <div className="delai-head" onClick={handleClickChevron4}>
                  <h2>Le Délai</h2>
                  <img
                    src={Chevron}
                    className={
                      clickedChevron4
                        ? "chevron-groupedOrder-open"
                        : "chevron-groupedOrder"
                    }
                    alt="chevron"
                  />
                </div>
                <div className="delai-content">
                  <p style={{ textAlign: "left" }}>
                    Dès que le nombre de personnes ayant commandées atteint 12,
                    je passe la commande, en tenant compte du temps de
                    fabrication, de l'envoi des livres par l'imprimeur vers moi,
                    puis de l'envoi de moi vers vous, je compte raisonnablement
                    un mois, mais ça peut-être beaucoup moins selon le nombre de
                    personnes désireuses d'avoir ce livre.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <TabPages />
          )}
          <p>
            Si cette offre vous intéresse, rendez-vous à mon formulaire de
            contact.
          </p>
          <br />
          <hr />
          <p>
            Je vous souhaite à tous de trouver la paix intérieure et une
            harmonie avec vos corps, car dans cette société effrénée qui
            accélère toujours plus, il est devenu nécessaire de se reconnecter
            avec notre esprit et notre corps, pour de nouveau ne faire plus
            qu’un avec soi-même, ainsi cessera la lutte intérieure qui déborde
            tout autour de nous et contamine le monde dans lequel nous vivons
            tous ensemble... Retrouver la paix intérieure nous fera retrouver le
            respect de soi et de chacun d’entre nous.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Books;
