import "./animateText.scss";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const AnimateText = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <div className="container-text">
      <svg
        viewBox="0 0 1418 116"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>Amano'Hilot</title>
        <g stroke="none" fill="none" fillRule="evenodd" fillOpacity="0">
          <text
            id="@amanohilot"
            stroke={!toggleActive ? "#000" : "#fff"}
            fill="#645F5A"
            fontWeight="normal"
            fontFamily="PermanentMarker-Regular, Permanent Marker"
            fontSize="144"
          >
            <tspan x="3" y="109">
              <tspan>A</tspan>
              <tspan>m</tspan>
              <tspan>a</tspan>
              <tspan>n</tspan>
              <tspan>o</tspan>
              <tspan>'</tspan>
              <tspan>H</tspan>
              <tspan>i</tspan>
              <tspan>l</tspan>
              <tspan>o</tspan>
              <tspan>t</tspan>
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default AnimateText;
