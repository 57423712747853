const MentionsLegales = () => {
  return (
    <div className="mentions">
      <h1
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textAlign: "center",
        }}
      >
        {" "}
      </h1>
      <span style={{ textAlign: "center" }}>
        <h3>
          Amano'Hilot est la propriété de :<br />
          Rodolphe AUGUSTO
        </h3>
        <p>
          18 rue du Général de Gaulle <br />
          37340 Ambillou
        </p>
        <p>SIREN : 443 694 740</p>
        <p>Indicatif Editeur AFNIL : 978-2-9577284</p>
      </span>
      <h3
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          fontSize: "14.2px",
        }}
      >
        Dispositions liées au code de la propriété intellectuelle
      </h3>
      <p>
        Toute représentation ou reproduction intégrale ou partielle faite sans
        notre consentement est illicite. Il en est de même pour la traduction,
        l'adaptation ou la transformation, l'arrangement ou la reproduction par
        un art ou un procédé quelconque (hors conditions prévues par l'article
        L122-5 du code de la propriété intellectuelle). Loi "Informatique et
        libertés" n° 78-17 du 6 janvier 1978
      </p>
      <h2
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        RGPD
      </h2>
      <p>
        Toutes les informations que ce site collecte sont : votre adresse mail
        si vous choisissez de vous abonnez à ma newsletter et dans le cas de
        l'utilisation du formulaire de contact, toutes les informations
        collectées, sont les seules informations que vous nous fournissez.
      </p>
      <p>
        Aucunes de vos données n'est revendues ou utilisées à d'autres fin que
        celle pour lequelles vous nous les avez soumis.
      </p>
      <p>L'usage de Cookie n'a de bût que pour votre expérience utilisateur.</p>
      <h2
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: "18.2px",
        }}
      >
        Responsable de la publication
      </h2>
      <p>Rodolphe Augusto</p>
      <h3
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Hébergement
      </h3>
      <p>Netlify</p>
      <h3
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Réalisation du site
      </h3>
      <p>
        Réalisé avec <i className="fa fa-heart" /> par Rodolphe Augusto
      </p>
      <h3
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Qu'est ce que les Cookies
      </h3>
      <br />
      <h5>Cookies et autres technologies de stockage</h5>
      <p>
        Les cookies sont de petits textes qui servent à stocker des informations
        sur les navigateurs web. Les cookies sont notamment utilisés pour
        stocker et recevoir des informations sur des appareils comme les
        ordinateurs ou les téléphones. D’autres technologies, dont les données
        que nous stockons sur votre navigateur web ou sur votre appareil, sont
        utilisées dans des buts similaires. Toutes ces technologies sont
        designées par le terme « cookies ».
      </p>
      <br />
      <h3
        style={{
          borderBottom: "1px solid #fd7e14",
          padding: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Pourquoi utilisons-nous des cookies ?
      </h3>
      <b>Performances</b>
      <p>
        Les cookies nous servent à rendre votre utilisation du site aussi
        agréable que possible.
      </p>
      <b>Fonctionnalités</b>
      <p>
        Nous utilisons des cookies pour les fonctionnalités basiques afin de
        vous garantir une bonne experience utilisateur.
      </p>
      <b>Sécurité et intégrité du site</b>
      <p>Nous utilisons des cookies pour garantir la sécurité de notre site.</p>
      <b>Nom du cookie et durée de vie</b>
      <p>
        Le cookie que nous utilisons sur ce site est nommé "amanoCookieSite", sa
        durée de vie n'est que de 10 jours.{" "}
      </p>
    </div>
  );
};

export default MentionsLegales;
