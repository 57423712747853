import "./switchDark.scss";
import { useRecoilState } from "recoil";
import toggleAtom from "../../stateManager/toggleAtom";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import { useEffect } from "react";

const SwitchDark = () => {
  const [toggle, setToggle] = useRecoilState(toggleAtom);
  const [toggleActive, setToggleActive] = useRecoilState(toggleActiveAtom);

  useEffect(() => {
    if (localStorage.getItem("mode") === null) {
      localStorage.setItem("mode", "light");
    }
    if (localStorage.getItem("mode") === "light") {
      setToggleActive(false);
    }
    if (localStorage.getItem("mode") === "dark") {
      setToggleActive(true);
    }
  }, [toggleActive, setToggleActive]);

  const handleChangeTheme = () => {
    if (!toggle) {
      setToggle(true);
      setToggleActive(false);
      localStorage.setItem("mode", "light");
    } else if (toggleActive) {
      localStorage.setItem("mode", "dark");
    }
    if (!toggle && toggleActive) {
      setToggle(false);
    } else {
      setToggle(true);
      setTimeout(() => {
        setToggle(false);
      }, 300);
      setToggleActive(true);
      localStorage.setItem("mode", "dark");
      // if (toggle) {
      //   setToggle(false);
      //   setToggleActive(false);
      // }
    }
  };

  // console.log("toggle", toggle);
  // console.log("toggle active", toggleActive);

  return (
    <div className="body-toggleDark">
      <span
        onClick={handleChangeTheme}
        className={
          toggle ? "toggle animate" : toggleActive ? "toggle active" : "toggle"
        }
      ></span>
      <span className={toggleActive ? "wave active" : "wave"}></span>
    </div>
  );
};

export default SwitchDark;
