import { Fragment } from "react";
import { data } from "../data/data";
import TheBookEdition from "../../../assets/images/plume.png";

import "./card.scss";

const Card = () => {
  return (
    <div className="card__collection ">
      {data.map((res) => (
        <div key={res.id} className="cards cards--three">
          <img src={res.img} className="img-responsive" alt="" />
          <span className="cards--three__rect-1">
            <span className="shadow-1"></span>
            <p className="price-container">{res.pages}</p>
            <p>
              {res.title} <br />
              {res.subTitle}
            </p>
          </span>
          <span className="cards--three__rect-2">
            <span className="shadow-2"></span>
            {res.isNew || res.isComingSoon ? (
              <p
                className="new-stick"
                style={{
                  position: "absolute",
                  color: "white",
                  fontWeight: "bold",
                  marginLeft: -5,
                  marginTop: -3,
                }}
              >
                {res.isComingSoon
                  ? "Bientôt"
                  : res.isExclu
                  ? "Exclusif"
                  : "Nouveauté"}
              </p>
            ) : null}
          </span>
          <span className="cards--three__circle"></span>
          <ul className="cards--three__list">
            <li className="link-list-books">
              {!res.isComingSoon && !res.isNoDispo ? (
                <Fragment>
                  <a
                    key={res.id}
                    href={`${res.href}`}
                    target="new"
                    className="books-contentLink"
                  >
                    <img src={TheBookEdition} alt="" />
                    <span style={{ fontSize: 17 }}>Acheter le Livre</span>{" "}
                  </a>
                  <div className="booksPrice-container">
                    <sub className="price-books">{res.price}</sub>{" "}
                  </div>
                </Fragment>
              ) : res.isNoDispo ? (
                <span className="sepia-span">
                  <img className="sepia" src={TheBookEdition} alt="" />
                  <span>Rupture de stock</span>{" "}
                  <div className="booksPrice-container">
                    <sub className="price-books">{res.price}</sub>{" "}
                  </div>
                </span>
              ) : (
                <span className="sepia-span">
                  <img className="sepia" src={TheBookEdition} alt="" />
                  <span>prochainement</span>{" "}
                </span>
              )}
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Card;
