import { useEffect } from "react";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";
import "./letterAnimation.css";
import { useRecoilState } from "recoil";

const LetterAnimation = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  useEffect(() => {
    const spans = document.querySelectorAll(".word span");

    spans.forEach((span, idx) => {
      span.addEventListener("click", (e) => {
        e.target.classList.add("active");
      });
      span.addEventListener("animationend", (e) => {
        e.target.classList.remove("active");
      });

      // Initial animation
      setTimeout(() => {
        span.classList.add("active");
      }, 750 * (idx + 1));
    });
  }, []);
  return (
    <div className={!toggleActive ? "word" : "word white"}>
      <span>T</span>
      <span>h</span>
      <span>é</span>
      <span>r</span>
      <span>a</span>
      <span>p</span>
      <span>e</span>
      <span>u</span>
      <span>t</span>
      <span>e</span>
      <span>&nbsp;</span>
      <span>H</span>
      <span>i</span>
      <span>l</span>
      <span>o</span>
      <span>t</span>
      <span>&nbsp;</span>
      <span>-</span>
      <span>&nbsp;</span>
      <span>A</span>
      <span>u</span>
      <span>t</span>
      <span>e</span>
      <span>u</span>
      <span>r</span>
      <span>&nbsp;</span>
      <span>-</span>
      <span>&nbsp;</span>
      <span>C</span>
      <span>o</span>
      <span>n</span>
      <span>s</span>
      <span>u</span>
      <span>l</span>
      <span>t</span>
      <span>a</span>
      <span>n</span>
      <span>t</span>
      <span>&nbsp;</span>
      <span>A</span>
      <span>u</span>
      <span>t</span>
      <span>o</span>
      <span>-</span>
      <span>é</span>
      <span>d</span>
      <span>i</span>
      <span>t</span>
      <span>i</span>
      <span>o</span>
      <span>n</span>
    </div>
  );
};

export default LetterAnimation;
