// import clickedGamesAtom from "../../stateManager/clickedGamesAtom";
import "./amanoGames.css";
import "./tooltip-style.css";
// import { useRecoilState } from "recoil";
const AmanoGames = ({ clickedChevron }) => {
  // const [clickGames, setClickGames] = useRecoilState(clickedGamesAtom);

  // function disableScrolling() {
  //   var x = window.scrollX;
  //   var y = window.scrollY;
  //   window.onscroll = function () {
  //     window.scrollTo(x, y);
  //   };
  // }

  // function enableScrolling() {
  //   window.onscroll = function () {};
  // }

  // const HandleClickGames = () => {
  //   if (!clickGames) {
  //     setClickGames(true);
  //     disableScrolling();
  //   }
  //   if (clickGames) {
  //     setClickGames(false);
  //     enableScrolling();
  //   }
  // };

  return (
    <div className={clickedChevron ? "amano-games none" : "amano-games"}>
      <br />
      <iframe
        scrolling="no"
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="920px"
        height="840px"
        src="https://amano-hilot-games.netlify.app/"
      ></iframe>
    </div>
  );
};

export default AmanoGames;
