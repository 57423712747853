/* eslint-disable no-unused-vars */
import "../../App.css";
import Header from "../header/Header";
import PictureScene from "../pictureScene/PictureScene";
import Presentation from "../presentation/Presentation";
import Books from "../books/Books";
import Edition from "../edition/Edition";
import Author from "../author/Author";
import Subscribe from "../subscribe/Subscribe";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import BottomNavigation from "../bottomNavigation/BottomNavigation";
import HeaderPhone from "../headerPhone/HeaderPhone";
import { Fragment, useEffect, useState } from "react";
import InfiniteLoopLoader from "../infiniteLoopLoader/InfiniteLoopLoader";
import { Fade, Slide } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import DerivatedProducts from "../derivatedProducts/DerivatedProducts";
import CookieConsent from "react-cookie-consent";
import SwitchDark from "../switch-dark/SwitchDark";
import WithCookies from "../../assets/images/with_cookies.png";
import ShoppingBadge from "../books/ebooksSection/shoppingBadge/ShoppingBadge";
import shoppingCartContentAtom from "../../stateManager/shoppingCartContentAtom";
import { useRecoilState } from "recoil";
import NewApplication from "../newApplication/NewApplication";

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [shoppingCartContent] = useRecoilState(shoppingCartContentAtom);
  const [showCookieBar, setShowCookieBar] = useState(true);
  const [cookieValue1, setCookieValue1] = useState(true);
  const [cookieValue2, setCookieValue2] = useState(true);
  const [cookieValue3, setCookieValue3] = useState(true);
  const [cookieValue4, setCookieValue4] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2250);
  });

  const handleClickCheckBox2 = () => {
    if (cookieValue2) {
      setCookieValue2(false);
    } else {
      setCookieValue2(true);
    }
  };
  const handleClickCheckBox3 = () => {
    if (cookieValue3) {
      setCookieValue3(false);
    } else {
      setCookieValue3(true);
    }
  };
  const handleClickCheckBox4 = () => {
    if (cookieValue4) {
      setCookieValue4(false);
    } else {
      setCookieValue4(true);
    }
  };
  return (
    <div className="Home">
      <Helmet>
        <title>Le site Amano'Hilot</title>
        <meta
          name="description"
          content="Visitez le site, vous pourrez éditer votre livre ou bien découvrir des livres, des goodies, des vêtements, ainsi que des accessoires de Yoga 'YogA'.  - Amano'Hilot est une entreprise centrée sur le soin et le bien-être! Au sein de cette établissement, je prodigue des soins Hilot, qui sont issus d'une technique de massage provenant des Philippines, mais pas seulement... Globalement, je concentre mon activité dans le soin à la personne, mais aussi dans la promotion de la médecine alternative et des activitées de bien-être. - Vous êtes auteur(e)s, et vous souhaitez auto-éditer une oeuvre? 5 *Choix s'offrent à vous ! - Je cible essentiellement les oeuvres faisant parties des catégories suivantes : Les livres traitants de la santé, du sport, du bien-être, ou encore des hobbies. Cela peut-être des livres techniques sur des sujets particuliers, des encyclopédies ou des méthodologies, ou encore des ouvrages contenants des conseils ou astuces dans les domaines cités plus haut."
        />
      </Helmet>
      {isLoaded ? (
        <Fragment>
          {showCookieBar && (
            <CookieConsent
              location="bottom"
              buttonText="J'ai compris"
              cookieName="amanoCookieSite"
              style={{
                background:
                  "linear-gradient(267deg, rgb(255 255 255) 0%, rgb(144 148 151) 100%)",
                textShadow: " 2px 2px black",
                borderTop: "1px solid #7510f7",
              }}
              buttonStyle={{
                background: "#7510f7",
                color: "white",
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
              }}
              expires={10}
              overlay
            >
              <span
                style={{
                  fontSize: "15px",
                  textShadow: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img style={{ width: 80 }} src={WithCookies} alt="" />
                <div>
                  <b>
                    🍪 Nous utilisons des cookies pour améliorer votre
                    expérience sur notre site.
                  </b>{" "}
                  <br />
                  En continuant votre navigation sur ce site, vous acceptez
                  l’utilisation de cookies pour mesurer l’audience et vous
                  proposer des fonctionnalités sociales.&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </span>
              <span className="checkCookies">
                <span>
                  <ul className="unstyled centered">
                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        value={cookieValue1}
                        checked={cookieValue1 ? true : false}
                        readOnly
                      />
                      <label htmlFor="styled-checkbox-1">Nécessaires</label>
                    </li>
                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-2"
                        type="checkbox"
                        value={cookieValue2}
                        checked={cookieValue2 ? true : false}
                        onChange={handleClickCheckBox2}
                      />
                      <label htmlFor="styled-checkbox-2">Préferences</label>
                    </li>
                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-3"
                        type="checkbox"
                        value={cookieValue3}
                        checked={cookieValue3 ? true : false}
                        onChange={handleClickCheckBox3}
                      />
                      <label htmlFor="styled-checkbox-3">Statistiques</label>
                    </li>

                    <li>
                      <input
                        className="styled-checkbox"
                        id="styled-checkbox-4"
                        type="checkbox"
                        value={cookieValue4}
                        checked={cookieValue4 ? true : false}
                        onChange={handleClickCheckBox4}
                      />
                      <label htmlFor="styled-checkbox-4">Marketing</label>
                    </li>
                  </ul>
                </span>
              </span>
              <button
                className="declineButtonCookies"
                onClick={() => setShowCookieBar(false)}
              >
                Décliner
              </button>
            </CookieConsent>
          )}
          {shoppingCartContent.length !== 0 && <ShoppingBadge />}
          <Header />
          <HeaderPhone />
          <SwitchDark />
          <PictureScene />
          <NewApplication />
          <Slide direction="left">
            <Presentation />
          </Slide>
          <Fade>
            <Books />
          </Fade>
          <Fade>
            <DerivatedProducts />
          </Fade>
          <Edition />
          <Fade>
            <Author />
          </Fade>
          <Fade>
            <Subscribe />
          </Fade>
          <Fade>
            <Contact />
          </Fade>
          <Footer />
          <BottomNavigation />
        </Fragment>
      ) : (
        <InfiniteLoopLoader />
      )}
    </div>
  );
}

export default Home;
