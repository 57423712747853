export const data = [
  {
    id: 1,
    title: "Aide à l'édition simple.",
    title2: "(Compte d'auteur)",
    description:
      " Vous serez guidé dans vos démarches, étape par étape. Toutes les étapes qui nécessitent un paiement seront à votre charges, néanmoins,",
    description2:
      "je vous conseillerais et vous assiterais tout du long de vos démarches de conception et de publication, et ce jusqu'à l'édition de votre ouvrage. ",
    description3:
      "Vous bénéficierez de l'impression à la demande avec mon imprimeur, ainsi que de la diffusion de l'annonce de votre livre sur mon réseau facebook. ",
    description4:
      "Vous aurez le logo de Amano'hilot en couverture, et donc ferez partie de la collection des livres présentés sur mon site, au côté des miens.",
    extension: false,
  },
  {
    id: 2,
    title: "Aide à l'édition avec prise en charge du design de couverture.",
    title2: "",
    description:
      " Cette option est similaire à la première, vous serez assité durant toutes les étapes, pas à pas,",
    description2:
      "avec, en plus, la prise en charge de la création de la couverture ( recto, verso et tranche), c'est à dire - conception graphique personnalisée et - conversion en gabarits prêt à l'envoi à l'imprimeur.",
    description3:
      "Vous bénéficierez aussi, de l'impression à la demande avec mon imprimeur, ainsi que de la diffusion de l'annonce de votre livre sur mon réseau facebook.",
    description4:
      "Et bien sur, vous aurez le logo de Amano'hilot en couverture, et donc ferez partie de la collection des livres présentés sur mon site, au côté des miens.",
    extension: false,
  },
  {
    id: 3,
    title: "Prise en charge complète de l'édition. ",
    title2: "(compte d'éditeur)",
    description:
      "Avec cette option, vous n'avez qu'une seule chose à faire... Ecrire le contenu de votre livre et c'est tout !",
    description2:
      " Toutes les étapes sont prises en charges complètement, démarches à faire et paiement de certaines étapes. Je m'occupe de la demande d'ISBN, du Dépôt (en votre nom) de vos ouvrages à la BNF, ainsi que toutes les étapes de mise au gabarits afin que le livre puisse être imprimé.",
    description3:
      " Vous bénéficierez aussi, bien sur, de l'impression à la demande avec mon imprimeur, ainsi que de la diffusion de l'annonce de votre livre sur mon réseau facebook.",
    description4:
      "Vous aurez aussi le logo de Amano'hilot en couverture, et donc ferez partie de la collection des livres présentés sur mon site, au côté des miens.",
    extension: false,
  },
  {
    id: 4,
    title: "Prise en charge complète avec site d'auteur et ",
    title2: "Emailing (Compte d'auteur)",
    description:
      " Cette option prend l'intégralité de l'offre complète, avec, en plus, la conception d'un site d'auteur (Single Page Application), la réservation de votre nom de domaine ainsi que l'hébergement de votre site.",
    description2:
      "Avec, ajouté à cela, une campagne d'emailing, à raison d'une campagne par mois. Vous bénéficierez de l'impression à la demande avec mon imprimeur comme dans toutes mes autres offres,",
    description3:
      " ainsi que de la diffusion de l'annonce de votre livre sur mon réseau facebook. ",
    description4:
      "Vous aurez le logo de Amano'hilot en couverture, et donc ferez partie de la collection des livres présentés sur mon site, au côté des miens.",
    extension: false,
  },
  {
    id: 5,
    title: "Vente de produits dérivés, ",
    title2: "avec confection des designs (Compte d'auteur)",
    description:
      " Cette option est une extension d'une autre offre, il faut avoir choisi une des autres offres pour être elligible à celle-ci, si vous souhaitez ce service, veuillez me le stipuler dans votre mail.",
    description2:
      "Je met en place votre ou vos boutiques 'Print on demand' sur différents sites avec lesquels je travail déjà, je mettrais en place pour vous, la création de votre compte, la personnalisation de votre boutique, ",
    description3:
      " la création des designs nécessaires à l'impression des différents objets. Il y a aussi la configuration de votre moyen de reception de vos royalty's afin que vous touchiez votre marge d'auteur à chaque vente de l'un de vos produits.",
    description4:
      "Plusieurs possibilités s'offrent à vous, n'hésitez pas à vous renseigner si vous souhaitez en savoir plus.",
    extension: true,
  },
];
