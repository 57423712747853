import "./edition.css";
// import Bg1 from "../../assets/bg-1.jpg";
import Container from "./Container";
import { Fragment } from "react";

const Edition = () => {
  return (
    <Fragment>
      <div className="edition" id="about">
        <Container />
      </div>
    </Fragment>
  );
};

export default Edition;
