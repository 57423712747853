import "./perspectiveCards.scss";
import { data } from "../data/data";
import { Zoom } from "react-awesome-reveal";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../../stateManager/toggleActiveAtom";

const PerspectiveCards = () => {
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <div className="l-container">
      {data.map((res) => (
        <div
          key={res.id}
          className="b-game-card "
          style={{ position: "relative" }}
        >
          <div
            className={
              toggleActive
                ? "b-game-card__cover b-game-card__cover-dark"
                : "b-game-card__cover"
            }
          >
            <Zoom triggerOnce>
              <h1>
                {res.title} <br /> {res.title2}
              </h1>
            </Zoom>
            <Zoom>
              <span className="editionTextP">
                {res.extension ? (
                  <Fragment>
                    <sub
                      style={{
                        position: "absolute",
                        marginTop: 10,
                        marginLeft: -156,
                        fontSize: 20,
                        fontStyle: "italic",
                      }}
                    ></sub>
                    <p style={{ fontStyle: "italic" }}>{res.description}</p>
                    <sub
                      className="author-parenthesis"
                      style={{
                        position: "absolute",
                        marginTop: -36,
                        right: 85,
                        fontSize: 20,
                        fontStyle: "italic",
                      }}
                    ></sub>
                  </Fragment>
                ) : (
                  <p> {res.description}</p>
                )}
                {res.extension ? (
                  <p>
                    {res.description2}
                    {res.description3}
                  </p>
                ) : (
                  <p>{res.description2}</p>
                )}

                {res.extension ? null : <p>{res.description3}</p>}

                <p>{res.description4}</p>
              </span>
            </Zoom>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PerspectiveCards;
