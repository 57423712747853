import { Fragment } from "react";
import "./derivatedProducts.scss";
import { data } from "./data/data";
import { Fade } from "react-awesome-reveal";
import RedBubble from "../../assets/redBubble.png";
import Zazzle from "../../assets/images/zazzle.png";
import useMobile from "../../hooks/useMobile";
import { useRecoilState } from "recoil";
import toggleActiveAtom from "../../stateManager/toggleActiveAtom";

const DerivatedProducts = () => {
  const { isMobile } = useMobile();
  const [toggleActive] = useRecoilState(toggleActiveAtom);
  return (
    <Fragment>
      <h1
        className={toggleActive ? "darkTransp" : ""}
        style={{
          backgroundColor: "#e5eaf6",
          marginTop: 2,
          paddingTop: 22,
          paddingBottom: 10,
        }}
        id="derivated"
      >
        Produits dérivés (YogA)
        {!isMobile ? <hr size="1" className="derivated-hr roundedHr" /> : null}
      </h1>

      <div
        className={
          toggleActive
            ? "derivatedProduct-container-dark"
            : "derivatedProduct-container"
        }
      >
        <Fade>
          {data.map((res) => (
            <div key={res.id} className="wrapper">
              <div className="container">
                <div className="top">
                  <img src={res.img} alt="" />
                </div>
                <div className="bottom">
                  <div className="left">
                    <div className="details">
                      <h1>{res.title}</h1>
                      <p>{res.price}</p>
                    </div>
                    <div className="buy">
                      <a href={res.href} target="new">
                        {res.href.includes("zazzle") ? (
                          <img
                            style={{
                              width: "50%",
                              marginTop: 20,
                              marginBottom: 10,
                            }}
                            src={Zazzle}
                            alt=""
                          />
                        ) : res.href.includes("redbubble") ? (
                          <img
                            style={{
                              width: "50%",
                              marginTop: 20,
                              marginBottom: 10,
                            }}
                            src={RedBubble}
                            alt=""
                          />
                        ) : (
                          <i
                            style={{ fontSize: 18 }}
                            className="fas fa-cart-plus"
                          ></i>
                        )}

                        <p
                          style={{
                            fontSize: 10,
                            marginTop: -40,
                            marginLeft: -10,
                          }}
                        >
                          voir l'article
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inside">
                <div className="icon">
                  <i className="fas fa-info-circle"></i>
                </div>
                <div className="contents">
                  <div
                    style={{ color: "white", fontWeight: "bold" }}
                    className="contentsDescription"
                  >
                    {res.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Fade>
      </div>
    </Fragment>
  );
};

export default DerivatedProducts;
