import Logo from "../../assets/new-app/logo-app2.svg";
import demo from "../../assets/new-app/1-home-v1.5.0.png";
import UserGuideLogo from "../../assets/new-app/logo-app2g.svg";
import "./newAppStyle.css";

const NewApplication = () => {
  return (
    <div className="newAppSection-container">
      <div className="Appcard">
        <h1>
          <span style={{ color: "#8537cc" }}>My Meditation Time.</span>
          <br />
          L'application
        </h1>
        <hr size="1" class="roundedHr" />
        <div className="headAppSection">
          <strong>
            C'est une application d'initiation et de pratique de la méditation.
          </strong>
          <p>Essayez et installer l'application : </p>
          <a
            className="linksApp"
            href="https://my-meditation-time.fr/"
            target="new"
          >
            <div className="testAppLink">
              <sub>Cliquez sur le logo:</sub>
              <img
                className="linkPict"
                src={Logo}
                alt="app"
                style={{ width: 70 }}
              />
            </div>
          </a>
        </div>
        <div className="logoPictureApp-content">
          <p>
            Le bût lors de la création de cette app était de rendre
            l'apprentissage de la méditation accessible à tous.
          </p>
          <sub>C'est chose faite avec succès !</sub>

          <img src={demo} alt="app" style={{ width: 350 }} />
          <div className="linkAppContent">
            <p>Toutes les infos sur l'application sont accessibles ici : </p>
            <a
              className="linksApp"
              href="https://rodolphe37.github.io/my-meditation-time-userguide/"
              target="new"
            >
              <div className="testAppLink">
                <sub>Guide d'utilisation:</sub>
                <img
                  className="linkPict"
                  src={UserGuideLogo}
                  alt="app"
                  style={{ width: 70 }}
                />
              </div>
            </a>
          </div>
        </div>
        <br />
        <p>Bonne méditation à vous !</p>
      </div>
      <br />
    </div>
  );
};

export default NewApplication;
