import First from "../../assets/mudras/1.png";
import Second from "../../assets/mudras/1b.png";
import Third from "../../assets/mudras/2.png";
import Four from "../../assets/mudras/3.png";
import Five from "../../assets/mudras/4.png";
import Six from "../../assets/mudras/5.png";
import Seven from "../../assets/mudras/6.png";
import Eight from "../../assets/mudras/7.png";
import Nine from "../../assets/mudras/8.png";
import Ten from "../../assets/mudras/9.png";

export const dataPictures = [
  {
    id: 0,
    pict: First,
  },
  {
    id: 1,
    pict: Second,
  },
  {
    id: 2,
    pict: Third,
  },
  {
    id: 3,
    pict: Four,
  },
  {
    id: 4,
    pict: Five,
  },
  {
    id: 5,
    pict: Six,
  },
  {
    id: 6,
    pict: Seven,
  },
  {
    id: 7,
    pict: Eight,
  },
  {
    id: 8,
    pict: Nine,
  },
  {
    id: 9,
    pict: Ten,
  },
];
